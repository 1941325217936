.Account {
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
  position: relative;
  height: calc(100vh - 90px);
  display: flex;
  overflow-x: hidden;
}

.LeftContainer {
  background-color: var(--primary-color);
  width: 25%;
  height: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  padding: 2rem;

  img {
    &:nth-child(1) {
      position: absolute;
      width: 230px;
      top: 0;
      left: 0;
    }

    &:nth-child(2) {
      position: absolute;
      width: 280px;
      bottom: 0;
      right: 0;
      z-index: 10;
    }
  }

  p {
    font-weight: 600;
    font-size: 21px;
    color: black;
  }
}

.RightContainer {
  background-color: #151515;
  width: 75%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
}

.DetailContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  background-color: #1b1b1b;
  padding: 20px 20px 5px 20px;
  border-radius: 10px;
  z-index: 2;
}

.DetailRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  gap: 16px;
}

.Detail {
  display: flex;
  flex-direction: column;
}

.Label {
  margin-top: 2px;
  margin-bottom: 10px;
  color: #8d8d8d;
  font-size: 13px;
}

.PhoneInput {
  background-color: #242424!important;
  width: 100%;
  border: none!important;
  border-radius: 8px!important;
}

.ButtonClass{
  background-color: #242424!important;
  border-radius: 8px!important;
  border: none!important;
}

.DropdownClass {
  overflow-x: hidden;
  width: 250px !important;
}

.ContainerClass{
  z-index: 9999!important;
}

.SearchClass{
  input{
    color: black!important;
  }
}

.InputWrapper {
  width: 340px;
  padding: 1px;
  height: 50px;
  background: linear-gradient(
                  142deg,
                  var(--secondary-color) 0%,
                  rgba(74, 66, 47, 1) 100%
  );
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Input {
  width: 100%;
  height: 100%;
  background-color: #242424;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;

  input {
    width: 100%;
    margin-left: 8px;
    font-weight: 500;
    font-size: 15px;
    background-color: transparent;
    border: none;
    outline: none;
    color: white;
    font-family: inherit;
  }
}

.IconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.TickIcon {
  background-color: #242424;
  color: #8a8a8a;
  height: 98%;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CountryCode {
  font-weight: 500;
  font-size: 15px;
  width: 50px;
  text-align: right;
  color: #8d8d8d;
}

.AccountStatus {
  font-weight: 500;
  font-size: 15px;
  color: white;
}

.StatusSwitch {
  width: 32px;
  height: 18px;
  border: 1px solid #5b5b5b;
  border-radius: 200px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 3px;
  justify-content: flex-end;
}

.StatusNob {
  background-color: var(--primary-color);
  border-radius: 9999px;
  width: 12px;
  height: 12px;
}

.PaletteContainer {
  width: fit-content;
  height: fit-content;
  background-color: #1b1b1b;
  border-radius: 10px;
  margin-top: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .DefaultColor {
    margin: 0;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    color: #8d8d8d;
    font-size: 13px;
    width: 694px;

    p {
      margin: 0;

      &:nth-child(2) {
        color: var(--primary-color);
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}

.ColorPalette {
  width: 100%;
  height: 50px;
  margin-bottom: 4px;
  border-radius: 10px;
  border: 1px solid #3a3a3a;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.Color {
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 8px;
}

.ColorPlus {
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 8px;
  border: 1px solid #949292;
  display: flex;
  justify-content: center;
  align-items: center;
}

.BottomButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 8px;
  margin-top: 28px;
}

.ChangePasswordBtn {
  width: 161px;
  height: 41px;
  border: 1px solid var(--primary-color);
  color: white;
  border-radius: 8px;
  background-color: transparent;
  font-family: inherit;
  font-weight: 500;
  margin-right: 6px;
}

.UpdateDetailsBtn {
  width: 161px;
  height: 41px;
  background: linear-gradient(91.3deg, var(--primary-color) 1.61%, #a97e2a 100%);
  color: white;
  border-radius: 8px;
  font-family: inherit;
  font-weight: 500;
  border: none;
  margin-left: 6px;

  p {
    margin: 0;
  }
}

.SpinnerContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MobileDetailContainer {
  width: 90%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -45px;
  display: none;
}

.MobileInputWrapper {
  width: 335px;
  height: 58px;
  background: linear-gradient(
                  142deg,
                  var(--secondary-color) 0%,
                  rgba(74, 66, 47, 1) 100%
  );
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.25);
  margin-bottom: 10px;
}

.MobileInput {
  width: 333px;
  height: 56px;
  background-color: #242424;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;

  input {
    width: 100%;
    margin-left: 8px;
    font-weight: 500;
    font-size: 15px;
    background-color: transparent;
    border: none;
    outline: none;
    color: white;
    font-family: inherit;

    &::placeholder {
      color: #8d8d8d;
    }
  }
}

.MobileAccountStatusLabelContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    color: #8d8d8d;
    font-weight: 500;
    font-size: 15px;
    margin-left: 8px;
  }
}

.MobileAccountStatusContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.MobileAccountStatus {
  color: white;
  margin-right: 10px;
}

.MobilePaletteContainer {
  width: 335px;
  height: 149px;
  background: linear-gradient(
                  142deg,
                  var(--secondary-color) 0%,
                  rgba(74, 66, 47, 1) 100%
  );
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.25);
  margin-top: -35px;
  position: relative;
  display: none;

  .MobilePalette {
    .DefaultColorSection {
      display: flex;
      justify-content: space-between;

      p {
        font-weight: 500;
        font-size: 15px;
        margin: 0;
        margin-bottom: 8px;

        &:nth-child(1) {
          color: #8d8d8d;
        }

        &:nth-child(2) {
          color: var(--primary-color);
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
}

.MobilePalette {
  width: 333px;
  height: 147px;
  background-color: #242424;
  border-radius: 8px;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.MobileColorPalette {
  width: 100%;
  height: 90px;
  border: 1px solid #3a3a3a;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px 15px;

  & > div {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

@media (max-width: 1440px) {
  .LeftContainer {
    img {
      &:nth-child(2) {
        width: 230px;
      }
    }

    p {
      font-size: 20px;
    }
  }
}

@media (max-width: 1230px) {
  .DetailContainer {
    transform: scale(0.9);
  }
  .PaletteContainer {
    transform: scale(0.9);
  }

  .BottomButtonContainer {
    transform: scale(0.9);
  }
}

@media (max-width: 1100px) {
  .LeftContainer {
    img {
      &:nth-child(1) {
        width: 190px;
      }

      &:nth-child(2) {
        width: 190px;
      }
    }

    p {
      font-size: 18px;
    }
  }
}

@media (max-width: 1024px) {
  .LeftContainer {
    display: none;
  }
  .RightContainer {
    width: 100%;
  }

  .DetailContainer {
    transform: scale(1);
  }
  .PaletteContainer {
    transform: none;
  }

  .BottomButtonContainer {
    transform: scale(1);
  }
}

@media (max-width: 768px) {
  .Account {
    flex-direction: column;
    height: 100%;
  }

  .LeftContainer {
    height: 223px;
    width: 100%;
    justify-content: flex-start;
    padding: 6%;
    display: block;

    img {
      &:nth-child(1) {
        display: none;
      }

      &:nth-child(2) {
        width: 200px;
      }
    }

    p {
      text-align: center;
      font-size: 20px;
    }
  }

  .RightContainer {
    width: 100%;
    justify-content: flex-start;
    height: calc(100vh - 200px);
    background-color: #212121;
  }

  .DetailContainer {
    display: none;
  }

  .PaletteContainer {
    display: none;
  }

  .Color {
    width: 26px;
    height: 28px;
    border-radius: 4px;
  }

  .ColorPlus {
    width: 26px;
    height: 28px;
    border-radius: 4px;
  }

  .MobileDetailContainer {
    display: flex;
    z-index: 12;
  }

  .MobilePaletteContainer {
    display: flex;
  }

  .BottomButtonContainer {
    padding-bottom: 50px;
  }
}
