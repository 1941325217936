:root {
    --primary-color: #D9B656;
    --secondary-color: #846F3B;
    --tertiary-color: #AA802B;
    --quaternary-color: #B99C51;
    --quinary-color: #AD893A;
}

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    cursor: default;
}

/* For WebKit-based browsers */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track {
    background: #1e1e1e; /* Dark background */
}

::-webkit-scrollbar-thumb {
    background: #444; /* Darker thumb */
    border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
    background: #666; /* Lighter on hover */
}

/* For Firefox */
* {
    scrollbar-color: #444 #1e1e1e;
    scrollbar-width: auto;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
    font-weight: bold;
    cursor: pointer;
}

button:disabled {
    opacity: 0.8;
    cursor: not-allowed;
}

.ant-table-wrapper {
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.ant-table-wrapper .ant-table {
    border-radius: 0;
}

.ant-table-wrapper .ant-table-thead > tr > th {
    background: #141414;
    border-bottom: none;
}

.ant-table-wrapper .ant-table-tbody > tr:nth-child(even) > td {
    border-left: 1px solid rgba(0, 0, 0, 0.2);
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom: none;
    background: #292929;
}

.ant-table-wrapper .ant-table-tbody > tr:nth-child(odd) > td {
    background: #222222;
    border-left: 1px solid rgba(0, 0, 0, 0.2);
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom: none;
}

.ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:first-child {
    border-start-start-radius: 0;
}

.ant-table-wrapper .ant-table-container table > thead > tr th {
    border-start-end-radius: 0;
    color: #838383;
}

.ant-table-wrapper .ant-table-tbody > tr.ant-table-row:hover:nth-child(odd) > td {
    background: #222222;
}

.ant-table-wrapper .ant-table-tbody > tr.ant-table-row:hover:nth-child(even) > td {
    background: #292929;
}

.ant-table-wrapper .ant-table-filter-trigger {
    color: #838383;
}

.ant-table-column-title {
    color: #838383;
}

.ant-statistic-title {
    font-size: 20px;
}

.ant-card-bordered {
    border-radius: 10px;
}

.ant-btn-default:not(:disabled):hover {
    color: #000000;
    border-color: #000000;
}

.border-none {
    border: none;
}

.ant-statistic-content-value span {
    font-size: 2.5rem;
}

.border-radius-5 {
    border-radius: 5px;
}

.border-radius-50 {
    border-radius: 50px;
}

.text-left {
    text-align: left;
}

.ant-table-cell {
    background-color: #303030;
    color: #BABABA;
}

.ant-table-thead .ant-table-cell {
    background-color: #303030;
    color: var(--primary-color);
    border-bottom: 1px solid var(--primary-color);
}

.ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background-color: #0D0D0D;
    color: white;
}

.ant-table-wrapper .ant-table-filter-trigger:hover {
    color: white;
}

.ant-table-wrapper .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    height: 100%;
    background: rgba(255, 255, 255, 0.1);
}

.ant-pagination-disabled a, .ant-pagination-disabled a:hover {
    color: #BBBBBB;
    cursor: not-allowed;
}

.ant-pagination.ant-pagination-mini .ant-pagination-prev .ant-pagination-item-link > span > svg {
    color: white;
}

.ant-pagination .ant-pagination-item-active a {
    color: white;
    background: #454545;
    border-radius: 50%;
}

.ant-pagination .ant-pagination-item a {
    color: #838383;
}


.ant-pagination.ant-pagination-mini .ant-pagination-prev {
    margin-right: 7px;

}

.ant-pagination.ant-pagination-mini .ant-pagination-next {
    margin-left: 7px;
}


.ant-pagination .ant-pagination-item-active a {
    background: #454545;
    color: white;
    border-radius: 50%
}

.ant-pagination .ant-pagination-item-active a:hover {
    color: white;
}

.ant-pagination .ant-pagination-item-active {
    border: none;
    background: transparent;
}

.ant-table-wrapper .ant-table.ant-table-small {
    background: black;
}

.react-tel-input input {
    border: none !important;
}

.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus {
    background-color: #151515 !important;
    border-radius: 8px !important;
}

.react-tel-input .flag-dropdown.open .selected-flag {
    background: #151515 !important;
    border-radius: 8px !important;
}

.ant-switch.ant-switch-checked {
    background: #2daf32;
}

.ant-switch:hover:not(.ant-switch-disabled) {
    background: #4b4b4b;
}

.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
    background: #2daf32;
}

.react-tel-input .flag-dropdown {
    background-color: transparent !important;
    border: none !important;
}

.react-tel-input .flag-dropdown.open {
    background-color: transparent !important;
}

.react-tel-input .country-list .country:hover, .react-tel-input .country-list .country.highlight {
    background-color: #151515 !important;
}

.ant-dropdown-menu {
    background-color: #242424 !important;

    .ant-dropdown-menu-title-content {
        margin: 2px 5px !important;
    }
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
    align-items: center;
    color: white !important;

    svg {
        color: #919191;
    }

    &:hover {
        background-color: rgba(255, 255, 255, 0.1) !important;
    }
}

.ant-select {
    height: 100% !important;
}

.ant-select-selector {
    /*min-height: 56px !important;*/
    height: 100% !important;
    background: #242424 !important;
    border: transparent !important;
    border-radius: 8px !important;
    outline: none !important;
    color: #919191 !important;
    padding: 0 !important;
    font-weight: 500 !important;
    font-size: 15px !important;

    .ant-select-selection-placeholder {
        padding-inline: 12px !important;
        color: #919191 !important;
    }

    .ant-select-selection-overflow {
        padding: 10px 16px !important;
    }

}


.ant-select-selection-item {
    color: #919191 !important;
    padding-inline: 12px !important;

}

.ant-select-selection-overflow {
    gap: 8px !important;
}

.ant-select-dropdown {
    background-color: #151515 !important;
}

.ant-select-item-option {
    color: #919191 !important;
    background-color: #151515 !important;
}

.ant-select-item-option-selected {
    background-color: #333 !important;
}

.ant-select-item-option-active {
    color: #919191 !important;
    background-color: #555 !important;
}

input {
    color-scheme: dark !important;
}

.ant-switch-inner span {
    font-weight: 600;
}

.ant-spin .ant-spin-dot-holder {
    color: #bbb;
}

.ant-select-item-option-state {
    color: #bbb !important;
}

.ant-select-selection-item {
    font-size: small;
}

.ant-switch-inner-checked {
    padding-top: 1px;
}