.CreateProfile {
  position: relative;
}

.Header {
  width: 100%;
  position: fixed;
  z-index: 10;
  top: 0;
  height: 90px;
  background-color: #2d2d2d;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  color: white;
}

.BackButtonWrapper {
  display: flex;
  align-items: center;
  width: 200px;
}

.BackButton {
  cursor: pointer;
  position: absolute;
}

.Title {
  margin-right: auto;
  margin-left: auto;
}

.ActionButtonsContainer {
  display: flex;
  gap: 32px;
  min-width: 200px;
  justify-content: center;
}

.SaveButton, .ChangeLayoutButton, .DeleteButton {
  min-width: 180px;
  width: fit-content;
  height: 45px;
  padding: 8px 16px;
  background: linear-gradient(91.3deg, var(--primary-color) 1.61%, #a97e2a 100%);
  border-radius: 8px;
  color: white;
  font-weight: 500;
  font-size: 15px;
  font-family: inherit;
  border: none;
  cursor: pointer;
}

.ChangeLayoutButton, .DeleteButton {
  border: 1px solid white;
  background: transparent !important;
}

.SaveButtonMobileTop {
  width: 100%;
  height: 40px;
  border-radius: 8px;
  color: white;
  font-weight: 500;
  font-size: 15px;
  font-family: inherit;
  border: none;
  cursor: pointer;
  display: none;
}

.TopContainer {
  width: 336px;
  // height: calc(100vh - 68px);
  padding-top: 90px;
  min-height: 100%;
  background-color: var(--primary-color);
  position: relative;

  img {
    &:nth-child(1) {
      width: 160px;
      margin-top: 27px;
      margin-left: 25px;
    }

    &:nth-child(2) {
      width: 270px;
      position: absolute;
      left: 0;
      top: 0;
    }

    &:nth-child(3) {
      width: 270px;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}


.CreateProfileFormContainer {
  width: 100%;
  background-color: transparent;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.MainContainer {
  display: flex;
  border-radius: 16px;
  flex-direction: column;
  align-items: center;
  gap: 40px
}

.CreateProfileForm {
  width: 50%;
  margin: 0 auto;
  margin-bottom: 64px;
}

.DropArea {
  cursor: pointer;
  padding: 32px 24px;
  width: 50%;
  max-width: 400px;
  border: 2px solid #424242;
  border-style: dashed;
  border-radius: 10px;
  background-color: #202020;
  display: flex;
  justify-content: center;
  align-items: center;


  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;

    div {
      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        font-weight: 500;
        font-size: 15px;
        color: #888888;
      }

      button {
        cursor: pointer;
        width: 141px;
        height: 38px;
        background-color: transparent;
        border: 1px solid #9c8c62;
        border-radius: 6px;
        color: #9c8c62;
        font-weight: 500;
        font-size: 15px;
        font-family: inherit;
        margin-bottom: 20px;
      }
    }
  }
}

.ImageContainer {
  border-radius: 50%;
  position: relative;

  img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }

  .IconContainer {
    background-color: #444;
    //width: 90%;
    //height: 90%;
    margin: auto;
    padding: 16px;
    border-radius: 50%;
  }
}

.ImageLoader {
  position: absolute !important;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  margin: auto;
}

.ClearButton {
  position: absolute;
  width: 24px;
  height: 24px;
  background-color: #151515;
  border: 1px solid gray;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  top: 0;
}

.MobileProfilePictureContainer {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 20px;

  p {
    color: #929292;
    font-weight: 500;
    font-size: 16px;
  }

  .ProfilePicture {
    width: 110px;
    height: 110px;
    display: flex;
    padding: 2px;
    justify-content: center;
    align-items: center;
    background: linear-gradient(
                    142deg,
                    var(--secondary-color) 0%,
                    rgba(48, 45, 21, 1) 100%
    );
    border-radius: 200px;

    & > div {
      background-color: #555;
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;

      div {
        background-color: #424242;
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
  }
}


.InputContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px 32px;
  align-items: stretch;
}

.InputContainer > div {
  flex-basis: 48%;
  min-width: 150px;
  max-width: 450px;
}


.ThemeButton {
  position: fixed;
  top: 140px;
  right: 0;
  background: #2d2d2d;
  padding: 5px 10px 2px 10px;
  border-radius: 25px 0 0 25px;
  z-index: 100;
}

.InputWrapper {
  width: 100%;
  padding: 0.8px 1px 0.8px 1px !important;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
                  142deg,
                  var(--secondary-color) 0%,
                  rgba(74, 66, 47, 1) 100%
  );
  margin-bottom: 10px;
  border-radius: 8px;

  & > div {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 5%;
    background-color: #242424;

    input,
    textarea {
      color: #919191;
      border: none;
      width: 100%;
      background-color: transparent;
      font-weight: 500;
      font-size: 15px;
      font-family: inherit;
      resize: none;

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: #919191;
      }
    }

    & > div {

      &:nth-child(1) {
        display: flex;

        svg {
          margin-top: 4px;
          margin-bottom: 4px;
        }
      }
    }
  }

}

.AdditionalFieldWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.DeleteFieldButton {
  background-color: #242424;
  border-radius: 8px;
  // width: 16%;
  width: 48px;
  margin-left: 10px;
  height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.AddFieldButton {
  cursor: pointer;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;

  p {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #828282;
    border-style: dashed;
    font-size: 14px;
    font-weight: 500;
    color: #b0b0b0;
  }
}

.SaveButtonMobile, .ChangeLayoutButtonMobile, .DeleteButtonMobile {
  width: 320px;
  background: linear-gradient(91.3deg, var(--primary-color) 1.61%, #a97e2a 100%);
  height: 56px;
  border-radius: 8px;
  border: none;
  color: white;
  font-weight: 500;
  font-size: 15px;
  font-family: inherit;
  margin-bottom: 32px;
  display: none;
}

.ChangeLayoutButtonMobile, .DeleteButtonMobile {
  background: transparent !important;
  border: 1px solid white !important;
}

.AddFieldModal {
  position: absolute;
  width: 335px;
  height: 185px;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  background-color: #363636;
  padding: 0 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  z-index: 160;
}

.PhoneInput {
  background-color: #242424 !important;
  width: 100% !important;
  height: 56px !important;
  border: none !important;
  border-radius: 8px !important;
}

.LeftButtonClass {
  background-color: #242424 !important;
  border-radius: 8px !important;
  border: none !important;
  height: 40px !important;
  top: unset !important;
  bottom: unset !important;
}

.RightButtonClass {
  background-color: #242424 !important;
  border-radius: 8px !important;
  border: none !important;
  height: 40px !important;
  top: 8px !important;
}


.DropdownClass {
  overflow-x: hidden;
  width: 250px !important;
}

.SearchClass {
  input {
    color: black !important;
    background-color: white !important;
  }
}

@media (max-width: 1350px) {
  .CreateProfileForm {
    width: 70%;
  }
}

@media (max-width: 1160px) {
  .TopContainer {
    width: 280px;
  }

  .CreateProfileForm {
    min-width: 300px;
    min-height: 400px;
  }
}

@media (max-width: 1024px) {
  .TopContainer {
    width: 240px;

    img {
      &:nth-child(2) {
        width: 210px;
        position: absolute;
        left: 0;
        top: 0;
      }

      &:nth-child(3) {
        width: 210px;
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
  }

  .CreateProfileFormContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .CreateProfileForm {
    width: 80%;
    margin: auto;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 1024px) {
  .CreateProfileForm {
    width: 60%;
    margin-bottom: 64px;
  }

  .InputContainer {
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;

    & > div {
      width: 100%;
    }
  }
}

@media (max-width: 768px) {
  .Header {
    height: 68px;
  }

  .BackButtonWrapper {
    width: 40px;
  }

  .ActionButtonsContainer {
    display: none;
  }

  .SaveButtonMobileTop {
    display: unset;
  }

  .Body {
    flex-direction: column;
  }

  .TopContainer {
    width: 100%;
    height: 128px;

    img {
      display: none;
    }
  }

  .CreateProfileFormContainer {
    flex-direction: column;
    gap: 16px;
    width: 100%;
    background-color: transparent;
    border-radius: 8px;
    margin-bottom: 24px;
  }

  .CreateProfileForm {
    width: 80%;
  }

  .DropArea {
    display: none;
  }

  .MobileProfilePictureContainer {
    display: flex;
  }

  .InputContainer {
    flex-direction: column;

    & > div {
      width: 100%;
    }
  }

  .SaveButtonMobile, .ChangeLayoutButtonMobile, .DeleteButtonMobile {
    display: block;
  }
}

@media (max-width: 576px) {
  .CreateProfileForm {
    width: 85%;
  }
}


.InputWrapper {
  width: 340px;
  padding: 1px;
  height: 50px;
  background: linear-gradient(
                  142deg,
                  var(--secondary-color) 0%,
                  rgba(74, 66, 47, 1) 100%
  );
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Input {
  width: 100%;
  height: 100%;
  background-color: #242424;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;

  input {
    width: 100%;
    margin-left: 8px;
    font-weight: 500;
    font-size: 15px;
    background-color: transparent;
    border: none;
    outline: none;
    color: white;
    font-family: inherit;
  }
}

.IconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.TickIcon {
  background-color: #242424;
  color: #8a8a8a;
  height: 98%;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CountryCode {
  font-weight: 500;
  font-size: 15px;
  width: 50px;
  text-align: right;
  color: #8d8d8d;
}

.AccountStatus {
  font-weight: 500;
  font-size: 15px;
  color: white;
}

.StatusSwitch {
  width: 32px;
  height: 18px;
  border: 1px solid #5b5b5b;
  border-radius: 200px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 3px;
  justify-content: flex-end;
}

.StatusNob {
  background-color: var(--primary-color);
  border-radius: 9999px;
  width: 12px;
  height: 12px;
}

.PaletteContainer {
  margin: auto;
  width: fit-content;
  height: fit-content;
  background-color: #1b1b1b;
  border-radius: 10px;

  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .DefaultColor {
    margin: 0;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    color: #8d8d8d;
    font-size: 13px;
    width: 694px;

    p {
      margin: 0;

      &:nth-child(2) {
        color: var(--primary-color);
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}

.ColorPalette {
  width: 100%;
  height: 50px;
  margin-bottom: 4px;
  border-radius: 10px;
  border: 1px solid #3a3a3a;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.Color {
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 8px;
}

.ColorPlus {
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 8px;
  border: 1px solid #949292;
  display: flex;
  justify-content: center;
  align-items: center;
}

.BottomButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 8px;
  margin-top: 28px;
}

.ChangePasswordBtn {
  width: 161px;
  height: 41px;
  border: 1px solid var(--primary-color);
  color: white;
  border-radius: 8px;
  background-color: transparent;
  font-family: inherit;
  font-weight: 500;
  margin-right: 6px;
}

.UpdateDetailsBtn {
  width: 161px;
  height: 41px;
  background: linear-gradient(91.3deg, var(--primary-color) 1.61%, #a97e2a 100%);
  color: white;
  border-radius: 8px;
  font-family: inherit;
  font-weight: 500;
  border: none;
  margin-left: 6px;

  p {
    margin: 0;
  }
}

.SpinnerContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MobileDetailContainer {
  width: 90%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -45px;
  display: none;
}

.MobileInputWrapper {
  width: 335px;
  height: 58px;
  background: linear-gradient(
                  142deg,
                  var(--secondary-color) 0%,
                  rgba(74, 66, 47, 1) 100%
  );
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.25);
  margin-bottom: 10px;
}

.MobileInput {
  width: 333px;
  height: 56px;
  background-color: #242424;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;

  input {
    width: 100%;
    margin-left: 8px;
    font-weight: 500;
    font-size: 15px;
    background-color: transparent;
    border: none;
    outline: none;
    color: white;
    font-family: inherit;

    &::placeholder {
      color: #8d8d8d;
    }
  }
}

.MobileAccountStatusLabelContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    color: #8d8d8d;
    font-weight: 500;
    font-size: 15px;
    margin-left: 8px;
  }
}

.MobileAccountStatusContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.MobileAccountStatus {
  color: white;
  margin-right: 10px;
}

.MobilePaletteContainer {
  width: 320px;
  height: 160px;
  background: linear-gradient(
                  142deg,
                  var(--secondary-color) 0%,
                  rgba(74, 66, 47, 1) 100%
  );
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.25);
  margin-top: -35px;
  position: relative;
  display: none;

  .MobilePalette {
    .DefaultColorSection {
      display: flex;
      justify-content: space-between;

      p {
        font-weight: 500;
        font-size: 15px;
        margin: 0;
        margin-bottom: 8px;

        &:nth-child(1) {
          color: #8d8d8d;
        }

        &:nth-child(2) {
          color: var(--primary-color);
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
}

.MobilePalette {
  width: 318px;
  height: 158px;
  background-color: #242424;
  border-radius: 8px;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.MobileColorPalette {
  width: 100%;
  height: 100px;
  border: 1px solid #3a3a3a;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px 15px;

  & > div {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

@media (max-width: 1440px) {
  .LeftContainer {
    img {
      &:nth-child(2) {
        width: 230px;
      }
    }

    p {
      font-size: 20px;
    }
  }
}

@media (max-width: 1100px) {
  .LeftContainer {
    img {
      &:nth-child(1) {
        width: 190px;
      }

      &:nth-child(2) {
        width: 190px;
      }
    }

    p {
      font-size: 18px;
    }
  }
}

@media (max-width: 1024px) {
  .LeftContainer {
    display: none;
  }
  .RightContainer {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .Account {
    flex-direction: column;
    height: 100%;
  }

  .MainContainer {
    background-color: #2d2d2d !important;
  }

  .LeftContainer {
    height: 223px;
    width: 100%;
    justify-content: flex-start;
    padding: 6%;
    display: block;

    img {
      &:nth-child(1) {
        display: none;
      }

      &:nth-child(2) {
        width: 200px;
      }
    }

    p {
      text-align: center;
      font-size: 20px;
    }
  }

  .RightContainer {
    width: 100%;
    justify-content: flex-start;
    height: calc(100vh - 200px);
    background-color: #212121;
  }

  .DetailContainer {
    display: none;
  }

  .PaletteContainer {
    display: none;
  }

  .Color {
    width: 26px;
    height: 28px;
    border-radius: 4px;
  }

  .ColorPlus {
    width: 26px;
    height: 28px;
    border-radius: 4px;
  }

  .MobileDetailContainer {
    display: flex;
    z-index: 12;
  }

  .MobilePaletteContainer {
    display: flex;
  }

  .BottomButtonContainer {
    padding-bottom: 50px;
  }
}

@media (max-width: 425px) {
  .Account {
    flex-direction: column;
    height: 100%;
  }

  .MainContainer {
    background-color: transparent !important;
  }
}

.Label {
  margin: 0;
  margin-bottom: 10px;
  color: #8d8d8d;
  font-size: 13px;
}

.InputBox {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
}

.InputWrapper {
  width: 320px;
  height: 50px;
  background: linear-gradient(
                  142deg,
                  var(--secondary-color) 0%,
                  rgba(74, 66, 47, 1) 100%
  );
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Input {
  width: 318px;
  height: 48px;
  background-color: #242424;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;

  input {
    width: 100%;
    margin-left: 8px;
    font-weight: 500;
    font-size: 15px;
    background-color: transparent;
    border: none;
    outline: none;
    color: white;
    font-family: inherit;
  }
}

.AccountStatus {
  font-weight: 500;
  font-size: 15px;
  color: white;
}

.StatusSwitch {
  width: 32px;
  height: 18px;
  border: 1px solid #5b5b5b;
  border-radius: 200px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 3px;
  justify-content: flex-end;
}

.StatusNob {
  background-color: var(--primary-color);
  border-radius: 9999px;
  width: 12px;
  height: 12px;
}

