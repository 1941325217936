.DashboardHeader {
  width: 100%;
  background-color: #2d2d2d;
  height: 90px;
  display: flex;
  align-items: center;
  position: relative;
}

.TenantModeHeader {
  height: 90px;
}

.QrButton {
  color: white;
  margin-right: 15px;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.QrContainer {
  display: flex;
  gap: 12px;
  align-items: center;
}

.HighPrivacySwitch {
  display: flex;
  margin-top: 4px;
  margin-right: 24px;
  gap: 8px;
  align-items: center;

  img {
    width: 35px;
  }

  p {
    margin: 0;
    margin-right: 8px;
    font-size: 12px;
    color: white;
  }
}

.DashboardHeaderHidden {
  display: flex;
}

.SideButtonContainer {
  background-color: #3f3f3f;
  color: #bcbcbc;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  position: relative;
  margin-left: 50px;
  cursor: pointer;
}

.PageTitle {
  color: white;
  width: max-content;
  font-weight: 500;
  font-size: 16px;
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
}

.MiniLogo {
  position: absolute;
  right: 50px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 35px;
}

.HeaderTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.HamburgerContainer {
  display: none;
  cursor: pointer;
  background-color: #3f3f3f;
  // width: 36px;
  height: 36px;
  padding: 0 18px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  color: white;
}

.HeaderBottom {
  display: none;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 1.2rem;
  padding: 0 24px;
}

.ButtonWrapper {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
}

.Greeting {
  font-weight: 600;
  font-size: 21px;

  h1 {
    margin: 0;
    color: white;
    font-size: 23px;
  }

  span {
    color: var(--primary-color);
  }
}

.AccountStatusActive {
  font-weight: 600;
  font-size: 14px;
  color: white;

  div {
    width: 110px;
    background-color: #2daf32;
    padding: 0.3rem 0.6rem;
    border-radius: 4px;
    display: flex;
    justify-content: center;
  }
}

.MyContactButton {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 13px;
  font-weight: bold;
  color: white;
  background: #151515;
  border: none;
  padding: 8px;
  border-radius: 5px;
  border-bottom: var(--primary-color) 2px solid;
}

.AccountStatusInactive {
  font-weight: 600;
  font-size: 14px;
  color: white;

  div {
    width: 110px;
    background-color: #CD3838;
    padding: 0.3rem 0.6rem;
    border-radius: 4px;
    display: flex;
    justify-content: center;
  }
}

.SpinnerContainer {
  width: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Logo {
  height: 43px;
  object-fit: contain;
  object-position: left; 
  margin-left: 50px;
}

.CollaborationWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 30px;
  color: white;

  img {
    margin-left: 0;
  }
}

.Logout {
  position: absolute;
  right: 50px;
  font-weight: 600;
  color: #CD3838;
  cursor: pointer;
}

@media (max-width: 768px) {
  .DashboardHeader {
    height: fit-content;
    padding: 24px 0;
    flex-direction: column;
    justify-content: space-between;
  }

  .TenantModeHeader {
    height: 180px;
  }

  .DashboardHeaderHidden {
    display: none;
  }

  .HamburgerContainer {
    display: flex;
    gap: 10px;
  }
  .HeaderTop {
    width: 100%;
    padding: 0 24px;
  }
  .HeaderBottom {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 20px;
  }
  .Logo {
    width: 148px;
    margin: 0;
  }
  .Logout {
    display: none;
  }
}
