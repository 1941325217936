.CreateProfile {
  position: relative;
}

.Header {
  width: 100%;
  position: fixed;
  z-index: 10;
  top: 0;
  height: 90px;
  background-color: #2d2d2d;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  color: white;
}

.BackButtonWrapper {
  display: flex;
  align-items: center;
  width: 200px;
}

.BackButton {
  cursor: pointer;
  position: absolute;
}

.Title {
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  align-items: center;
}

.ActionButtonsContainer {
  display: flex;
  gap: 16px;
  min-width: 200px;
  justify-content: end
}

.SaveButton, .ChangeLayoutButton, .DeleteButton {
  width: fit-content;
  height: 40px;
  padding: 8px 16px;
  background: linear-gradient(91.3deg, var(--primary-color) 1.61%, #a97e2a 100%);
  border-radius: 8px;
  color: white;
  font-weight: 500;
  font-size: 15px;
  font-family: inherit;
  border: none;
  cursor: pointer;
}

.ChangeLayoutButton, .DeleteButton {
  border: 1px solid white;
  background: transparent !important;
}

.SaveButtonMobileTop {
  width: 100%;
  height: 40px;
  border-radius: 8px;
  color: white;
  font-weight: 500;
  font-size: 15px;
  font-family: inherit;
  border: none;
  cursor: pointer;
  display: none;
}

.Body {
  display: flex;
  min-height: 100vh;
  position: relative;
}

.TopContainer {
  width: 336px;
  // height: calc(100vh - 68px);
  padding-top: 90px;
  min-height: 100%;
  background-color: var(--primary-color);
  position: relative;

  img {
    &:nth-child(1) {
      width: 160px;
      margin-top: 27px;
      margin-left: 25px;
    }

    &:nth-child(2) {
      width: 270px;
      position: absolute;
      left: 0;
      top: 0;
    }

    &:nth-child(3) {
      width: 270px;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}


.CreateProfileFormContainer {
  width: calc(100% - 336px);
  background-color: #151515;
  padding-top: 150px;
  display: flex;
  justify-content: center;
}

.CreateProfileForm {
  width: 50%;
  margin-bottom: 64px;
}

.DropArea {
  cursor: pointer;
  padding: 32px 24px;
  width: 100%;
  border: 2px solid #424242;
  border-style: dashed;
  border-radius: 10px;
  margin-bottom: 30px;
  background-color: #202020;
  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;

    div {
      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        font-weight: 500;
        font-size: 15px;
        color: #888888;
      }

      button {
        cursor: pointer;
        width: 141px;
        height: 38px;
        background-color: transparent;
        border: 1px solid #9c8c62;
        border-radius: 6px;
        color: #9c8c62;
        font-weight: 500;
        font-size: 15px;
        font-family: inherit;
        margin-bottom: 20px;
      }
    }
  }
}

.ImageContainer {
  width: 100px;
  height: 100px;
  border-radius: 50%;

  position: relative;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  .IconContainer {
    background-color: #444;
    width: 90%;
    height: 90%;
    margin: auto;
    padding: 16px;
    border-radius: 50%;
  }
}

.ImageLoader {
  position: absolute !important;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  margin: auto;
}

.ClearButton {
  position: absolute;
  width: 24px;
  height: 24px;
  background-color: #151515;
  border: 1px solid gray;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  top: 0;
}

.MobileProfilePictureContainer {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 20px;

  p {
    color: #929292;
    font-weight: 500;
    font-size: 16px;
  }

  .ProfilePicture {
    width: 110px;
    height: 110px;
    display: flex;
    padding: 2px;
    justify-content: center;
    align-items: center;
    background: linear-gradient(
                    142deg,
                    var(--secondary-color) 0%,
                    rgba(48, 45, 21, 1) 100%
    );
    border-radius: 200px;

    & > div {
      background-color: #555;
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;

      div {
        background-color: #424242;
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
  }
}


.InputContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 16px; /* Spacing between fields */
  align-items: stretch; /* Ensure input fields align evenly */
}

.InputContainer > div {
  flex-basis: 48%; /* Ensure inputs stay on both sides */
  min-width: 150px; /* Prevent inputs from collapsing */
}


.ThemeButton {
  position: fixed;
  top: 140px;
  right: 0;
  background: #2d2d2d;
  padding: 5px 10px 2px 10px;
  border-radius: 25px 0 0 25px;
  z-index: 100;
}

.InputWrapper {
  width: 100%;
  padding: 0.8px 1px 0.8px 1px !important;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
                  142deg,
                  var(--secondary-color) 0%,
                  rgba(74, 66, 47, 1) 100%
  );
  margin-bottom: 12px;
  border-radius: 8px;

  & > div {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 5%;
    background-color: #242424;

    input,
    textarea {
      color: #919191;
      border: none;
      width: 100%;
      background-color: transparent;
      font-weight: 500;
      font-size: 15px;
      font-family: inherit;
      resize: none;

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: #919191;
      }
    }

    & > div {

      &:nth-child(1) {
        display: flex;

        svg {
          margin-top: 4px;
          margin-bottom: 4px;
        }
      }
    }
  }

}

.AdditionalFieldWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.DeleteFieldButton {
  background-color: #242424;
  border-radius: 8px;
  // width: 16%;
  width: 48px;
  margin-left: 10px;
  height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.AddFieldButton {
  cursor: pointer;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  margin-bottom: 36px;

  p {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #828282;
    border-style: dashed;
    font-size: 14px;
    font-weight: 500;
    color: #b0b0b0;
  }
}

.SaveButtonMobile, .ChangeLayoutButtonMobile, .DeleteButtonMobile {
  width: 100%;
  background: linear-gradient(91.3deg, var(--primary-color) 1.61%, #a97e2a 100%);
  height: 56px;
  border-radius: 8px;
  border: none;
  color: white;
  font-weight: 500;
  font-size: 15px;
  font-family: inherit;
  margin-bottom: 16px;
  display: none;
}

.ChangeLayoutButtonMobile, .DeleteButtonMobile {
  background: transparent !important;
  border: 1px solid white !important;
}

.AddFieldModal {
  position: absolute;
  width: 335px;
  height: 185px;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  background-color: #363636;
  padding: 0 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  z-index: 160;
}

.PhoneInput {
  background-color: #242424 !important;
  width: 100% !important;
  height: 56px !important;
  border: none !important;
  border-radius: 8px !important;
}

.LeftButtonClass {
  background-color: #242424 !important;
  border-radius: 8px !important;
  border: none !important;
  height: 40px !important;
  top: unset !important;
  bottom: unset !important;
}

.RightButtonClass {
  background-color: #242424 !important;
  border-radius: 8px !important;
  border: none !important;
  height: 40px !important;
  top: 8px !important;
}


.DropdownClass {
  overflow-x: hidden;
  width: 250px !important;
}

.SearchClass {
  input {
    color: black !important;
    background-color: white !important;
  }
}

@media (max-width: 1350px) {
  .CreateProfileForm {
    width: 70%;
  }
}

@media (max-width: 1160px) {
  .TopContainer {
    width: 280px;
  }

  .CreateProfileForm {
    min-width: 300px;
    min-height: 400px;
  }
}

@media (max-width: 1024px) {
  .TopContainer {
    width: 240px;

    img {
      &:nth-child(2) {
        width: 210px;
        position: absolute;
        left: 0;
        top: 0;
      }

      &:nth-child(3) {
        width: 210px;
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
  }

  .CreateProfileFormContainer {
    width: calc(100% - 240px);
  }

  .CreateProfileForm {
    width: 80%;
  }
}

@media (max-width: 1024px) {
  .CreateProfileForm {
    width: 60%;
    margin-bottom: 64px;
  }

  .InputContainer {
    flex-direction: column;

    & > div {
      width: 100%;
    }
  }
}

@media (max-width: 768px) {
  .Header {
    height: 68px;
  }

  .BackButtonWrapper {
    width: 40px;
  }

  .ActionButtonsContainer {
    display: none;
  }

  .SaveButtonMobileTop {
    display: unset;
  }

  .Body {
    flex-direction: column;
  }

  .TopContainer {
    width: 100%;
    height: 128px;

    img {
      display: none;
    }
  }

  .CreateProfileFormContainer {
    width: 100%;
    background-color: #212121;
  }

  .CreateProfileForm {
    width: 80%;
  }

  .DropArea {
    display: none;
  }

  .MobileProfilePictureContainer {
    display: flex;
  }

  .InputContainer {
    flex-direction: column;

    & > div {
      width: 100%;

      &:nth-child(2) {
        margin-top: 20px;
      }
    }
  }

  .SaveButtonMobile, .ChangeLayoutButtonMobile, .DeleteButtonMobile {
    display: block;
  }
}

@media (max-width: 576px) {
  .CreateProfileForm {
    width: 85%;
  }
}
