.Settings {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.MiddleContainer {
    display: flex;
    gap: 32px;
    margin: 16px;
    justify-content: center;
}

.SettingsList {
    display: flex;
    gap: 1rem;
    width: 100%;
    list-style: none;
    margin: 0;
    padding-inline: 0;


    li {
        width: 100%;
        color: white;
        display: flex;
        justify-content: start;
        align-items: center;
        gap: 32px;

        p {
            margin: 0;
        }
    }
}

.ImageUpload {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
    gap: 28px;
    //width: 340px;
    height: 270px;
    background: #202020;
    border: 2px dashed #424242;
    border-radius: 8px;
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.25);

    img {
        width: 78.67px;
        height: 78px;
    }

    p {
        color: #888888;
        margin: 0;
        text-align: center;
    }

    .ImageContainer {
        cursor: pointer;
        width: 78px;
        height: 78px;
        position: relative;

        img {
            width: 78px;
            height: 78px;
            border-radius: 200px;
        }
    }

    .ClearButton {
        position: absolute;
        width: 22px;
        height: 22px;
        background-color: #151515;
        border-radius: 9999px;
        display: flex;
        justify-content: center;
        align-items: center;
        right: 0;
        top: 0;
    }

    button {
        color: #9C8C62;
        background: transparent;
        border: 1px solid #9C8C62;
        padding: 12px 24px;
        border-radius: 8px;
        font-size: 15px;
    }
}

.Detail {
    display: flex;
    flex-direction: column;
}

.Label {
    margin: 0;
    margin-bottom: 8px;
    color: #8d8d8d;
    font-size: 13px;
}

.InputBox {
    display: flex;
    flex-direction: column;
    gap: 40px;
    justify-content: center;
}

.InputWrapper {
    width: 335px;
    height: 50px;
    background: linear-gradient(142deg,
            var(--secondary-color) 0%,
            rgba(74, 66, 47, 1) 100%);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Input {
    width: 333px;
    height: 48px;
    background-color: #242424;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;

    input {
        width: 100%;
        margin-left: 8px;
        font-weight: 500;
        font-size: 15px;
        background-color: transparent;
        border: none;
        outline: none;
        color: white;
        font-family: inherit;
    }
}

.AccountStatus {
    font-weight: 500;
    font-size: 15px;
    color: white;
}

.StatusSwitch {
    width: 32px;
    height: 18px;
    border: 1px solid #5b5b5b;
    border-radius: 200px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0 3px;
    justify-content: flex-end;
}

.StatusNob {
    background-color: var(--primary-color);
    border-radius: 9999px;
    width: 12px;
    height: 12px;
}

.ColorSection {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 720px;
    flex-direction: column;
    gap: 40px;
}

.PaletteContainer {
    width: 726px;
    height: 107px;
    background-color: #1b1b1b;
    border-radius: 10px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .DefaultColor {
        margin: 0;
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
        color: #8d8d8d;
        font-size: 13px;
        width: 694px;

        p {
            margin: 0;

            &:nth-child(2) {
                color: var(--primary-color);
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
}

.ColorPalette {
    width: 694px;
    height: 50px;
    border-radius: 10px;
    border: 1px solid #3a3a3a;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
}

.Color {
    cursor: pointer;
    width: 24px;
    height: 24px;
    border-radius: 8px;
}

.ColorPlus {
    cursor: pointer;
    width: 24px;
    height: 24px;
    border-radius: 8px;
    border: 1px solid #949292;
    display: flex;
    justify-content: center;
    align-items: center;
}

.MobilePaletteContainer {
    width: 335px;
    height: 149px;
    background: linear-gradient(142deg,
            var(--secondary-color) 0%,
            rgba(74, 66, 47, 1) 100%);
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.25);
    margin-top: -35px;
    position: relative;
    display: none;

    .MobilePalette {
        .DefaultColorSection {
            display: flex;
            justify-content: space-between;

            p {
                font-weight: 500;
                font-size: 15px;
                margin: 0;
                margin-bottom: 8px;

                &:nth-child(1) {
                    color: #8d8d8d;
                }

                &:nth-child(2) {
                    color: var(--primary-color);
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }
    }
}

.MobilePalette {
    width: 333px;
    height: 147px;
    background-color: #242424;
    border-radius: 8px;
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.MobileColorPalette {
    width: 100%;
    height: 90px;
    border: 1px solid #3a3a3a;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 12px 15px;

    &>div {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
}

.UpdateDetailsBtn {
    width: 161px;
    height: 41px;
    background: linear-gradient(91.3deg, var(--primary-color) 1.61%, #a97e2a 100%);
    color: white;
    border-radius: 8px;
    font-family: inherit;
    font-weight: 500;
    border: none;
    margin-left: 6px;

    p {
        margin: 0;
    }
}

.SpinnerContainer {
    padding: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 1230px) {
    .MiddleContainer {
        transform: scale(0.9);
    }

    .PaletteContainer {
        transform: scale(0.9);
    }
}

@media (max-width: 1024px) {
    .Title {
        text-align: center;
    }

    .MiddleContainer {
        flex-direction: column;
        align-items: center;
        transform: scale(1);
    }

    .PaletteContainer {
        display: none;
    }

    .MobilePaletteContainer {
        margin-top: 10px;
        display: flex;
    }

    .InputBox {
        align-items: center;
    }

    .ImageUpload {
        width: 340px;
    }
}

@media (max-width: 450px) {
    .MiddleContainer {
        margin: 0;
    }

    .Title {
        font-size: 20px !important;
    }

    .ImageUpload {
        border: none;
        background: none;
        box-shadow: none;
        height: 165px;

        p {
            display: none;
        }
    }
}