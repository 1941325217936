.ChangeLayoutModal {
  width: 540px;
  height: fit-content;
  background-color: #2c2c2c;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  z-index: 160;

  h6 {
    margin: 0;
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 18px;
    color: white;
  }
}

.IconDisplayContainer {
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  flex-wrap: wrap;
  gap: 18px 24px;
  margin-top: 12px;
  margin-bottom: 4px;
  border-radius: 10px;
  padding: 20px 40px;
  justify-content: center;
  align-items: center;
}

.IconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 1px solid #fff;

  div {
    margin-top: 4px;
  }
}

.CloseButtonContainerLeft {
  display: none;
  justify-content: flex-end;

  div {
    cursor: pointer;
  }
}

.MainContainer {
  border-radius: 16px;
  background-color: #2c2c2c;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.CloseButtonContainerRight {
  display: flex;
  justify-content: flex-end;

  div {
    cursor: pointer;
    padding-top: 16px;
    padding-right: 16px;
  }
}

.MainContainerContent {
  height: 100%;
  padding: 32px;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .InfoContainer {

    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 12px;
    gap: 8px;

    p {
      line-height: 1.5;
      text-align: center;
      color: #8f8f8f;
      margin: 0;
    }
  }


}

.InputContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;

}

.InputWrapper {
  width: 100%;
  height: fit-content;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.25);
  background: linear-gradient(
                  142deg,
                  var(--secondary-color) 0%,
                  rgba(48, 45, 21, 1) 100%
  );

  .Input {
    width: 100%;
    height: 56px;
    background-color: #242424;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;

    input {
      font-family: inherit;
      background-color: #242424;
      color: #919191;
      font-weight: 500;
      font-size: 15px;
      border: none;

      &::placeholder {
        color: #919191;
      }

      &:focus {
        outline: none;
      }
    }
  }
}

.SaveFieldButton {
  cursor: pointer;
  width: 100%;
  height: 52px;
  border-radius: 8px;
  border: none;
  background: linear-gradient(
                  142deg,
                  var(--primary-color) 0%,
                  var(--tertiary-color) 100%
  );
  color: white;
  font-family: inherit;
  font-weight: 500;
  font-size: 14px;
  margin-top: 36px;
}

@media (max-width: 768px) {
  .AddFieldModal {
    flex-direction: column;
    width: 350px;
    height: fit-content;
  }

  .LeftContainer {
    width: 100%;
    height: 55%;
    padding-left: 24px;
    padding-right: 24px;
    background-color: transparent;
  }
  .CloseButtonContainerLeft {
    display: flex;

    div {
      position: relative;
      right: -10px;
    }
  }

  .SelectedIconContainer {
    margin-top: 0;
    margin-bottom: 15px;
  }

  .SearchInputWrapper {
    margin-bottom: 12px;
  }

  .IconContainer {
    height: 250px;
    background-color: #252525;

    & > div {
      div {
        margin-left: 7px;
        margin-right: 7px;
        margin-bottom: 7px;
      }
    }
  }


  .SaveFieldButton {
    width: 100%;
    margin-top: 40px;
  }
}
