.Input {
/*  background: rgb(132, 111, 59);
  background: linear-gradient(
    142deg,
    rgba(132, 132, 59, 1) 0%,
    rgba(53, 47, 32, 1) 100%
  );*/
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px;
  height: 58px;
  margin-bottom: 15px;
  width: 100%;

  input {
    -webkit-appearance: none;
    width: 100%;
    background-color: #151515;
    border: none;
    outline: none;
    border-radius: 0 8px 8px 0;
    height: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    color: white;
  }
}

.NoBorderRadius {
  border-radius: 0 !important;
}

.IconContainer {
  background-color: #151515;
  padding-left: 1rem;
  color: #8a8a8a;
  height: 100%;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ViewPassword {
  background-color: #151515;
  padding-right: 1rem;
  color: #8a8a8a;
  height: 100%;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
