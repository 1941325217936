.Card{
  background: #242424;
  border-radius: 10px;
  padding: 24px;
  margin-bottom: 20px;

  h4{
    margin: 0;
    color: rgba(255,255,255,0.5) ;
    font-size: 14px;
    font-weight: 400;
  }

  .EditAndDeleteButtons{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 6px 0;

    p{
      color: var(--primary-color);
      font-size: 15px;
      font-weight: 500;
      margin: 0;
    }

    div{
      display: flex;
      gap: 8px;
      button{
        background: #3A3939;
        border-radius: 6px;
        border: none;
        padding: 8px;
        width: 28px;
        height: 28px;
      }
    }
  }

  .PreviewButtonSection{
    width: 100%;
    margin-top: 20px;

    .Button {
      background-color: #3a3939;
      border-radius: 6px;
      height: 37px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      padding: 0 10px;
      cursor: pointer;
      border: none;
      p {
        color: #9c9c9c;
        font-size: 14px;
        margin-left: 8px;
      }
    }
  }
}
