.StatusChangeConfirmation {
  position: absolute;
  width: 360px;
  height: fit-content;
  background-color: #363636;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 10px;
  z-index: 160;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.CloseButtonContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  & > div {
    cursor: pointer;
  }
}

.ConfirmationText {
  font-weight: 600;
  font-size: 18px;
  color: white;
  text-align: center;
  line-height: 22px;
  margin-top: 10px;
}

.InfoText {
  font-size: 14px;
  text-align: center;
  color: #9a9a9a;
  margin-top: 4px;
}

.DeactivateButton {
  background: linear-gradient(
    142deg,
                  var(--primary-color) 0%,
                  var(--tertiary-color) 100%
  );
  color: white;
  border: none;
  width: 100%;
  height: 52px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  margin-top: 20px;
}
