.Contact {
  height: 72px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  background-color: #202020;
  border-radius: 8px;
  border: 1.5px solid #383838;
  gap: 12px
}

.LeftContainer {
  display: flex;
  gap: 16px;
  width: 100%;

  .InitialsContainer {
    height: 36px;
    width: 36px;
    background-color: #414141;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      font-weight: 500;
      font-size: 15px;
      color: var(--primary-color);
    }
  }

  .DetailContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
      margin: 0;

      &:nth-child(1) {
        width: 100%;
        max-width: 55vw;
        font-weight: 500;
        color: white;
        font-size: 15px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &:nth-child(2) {
        color: #828282;
        font-size: 13px;
        margin-top: 3px;
      }
    }
  }
}

@media (min-width: 1440px) {
  .DetailContainer {

    p {
      &:nth-child(1) {
        max-width: 6vw !important;
      }
    }
  }
}

@media (min-width: 1200px) {
  .DetailContainer {

    p {
      &:nth-child(1) {
        max-width: 8vw !important;
      }
    }
  }
}

@media (max-width: 768px) {
  .Contact {
    width: 100%;
    height: 45px;
    padding: 10px;
    margin-bottom: 16px;
    border: none;
  }

  .LeftContainer {
    .InitialsContainer {
      height: 45px;
      width: 45px;

      p {
        font-size: 18px;
      }
    }
  }

  .DetailContainer {
    //margin-left: 10px;

    p {
      &:nth-child(1) {
        width: auto !important;
      }

      &:nth-child(2) {
        font-size: 14px;
      }
    }
  }
}
