.ForgotPasswordHeaderContainer {
  height: 100%;
  background: linear-gradient(
                  114.54deg,
                  #000000 0.12%,
                  rgba(171, 171, 171, 0.36) 100%
  );
}

.ForgotPasswordHeader {
  height: 100vh;
  position: relative;
  width: 425px;
  display: flex;
  justify-content: center;
  align-items: center;

  img{
    width: 300px;
  }
}

@media (max-width: 1024px) {
  .ForgotPasswordHeader {
    width: 340px;
  }
}

@media (max-width: 768px) {
  .ForgotPasswordHeaderContainer {
    height: max-content;
  }

  .ForgotPasswordHeader {
    width: 100%;
    height: 255px;

    img{
      //width: 200px;
      display: none;
    }
  }
}
@media (max-width: 425px) {
  .ForgotPasswordHeaderContainer{
    display: none;
  }
}
