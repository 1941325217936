.ShareableLink {
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
  position: relative;
  height: calc(100vh - 90px);
  display: flex;
}

.LeftContainer {
  background-color: var(--primary-color);
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  position: relative;

  img {
    &:nth-child(1) {
      position: absolute;
      width: 230px;
      top: 0;
      left: 0;
    }

    &:nth-child(2) {
      position: absolute;
      width: 280px;
      bottom: 0;
      right: 0;
      z-index: 10;
    }
  }
}

.LeftInnerContainer {
  display: flex;
  flex-direction: column;
  width: 405px;
  margin-left: 5px;

  p {
    color: black;
    margin: 0;

    &:nth-child(1) {
      font-size: 28px;
      font-weight: 600;
      margin-bottom: 20px;
    }

    &:nth-child(2) {
      font-size: 18px;
      font-weight: 400;
    }
  }
}

.RightContainer {
  background-color: #151515;
  width: 60%;
  height: 100%;
  display: flex;
  padding: 64px 120px 20px 120px;
  flex-direction: column;
  gap: 32px;

  .BuyButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 37px;

    h1 {
      margin: 0;
      color: white;
      font-size: 24px;
    }

    div {
      display: flex;
      gap: 16px;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        padding: 10px 16px;
        font-size: 14px;
        font-weight: 400;
        border: none;

        &:nth-child(1) {
          background: #3A3939;
          color: rgba(255, 255, 255, 0.5);
        }

        &:nth-child(2) {
          background: var(--primary-color);
        }
      }
    }
  }

}

.LinkListItemWrapper {
  width: 405px;
  height: fit-content;
  background: linear-gradient(
                  142deg,
                  var(--secondary-color) 0%,
                  rgba(48, 45, 21, 1) 100%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  margin-bottom: 15px;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.25);
  position: relative;
  z-index: 10;
}

.LinkListItem {
  width: 448px;
  height: fit-content;
  background-color: #151515;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.LinkContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  margin-bottom: 42px;
}

.Link {
  display: flex;
  height: 100%;

  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;

  p {
    margin: 0;

    &:nth-child(1) {
      color: #919191;
      font-size: 14px;
    }

    &:nth-child(2) {
      color: var(--primary-color);
      font-weight: 500;
      font-size: 15px;
    }

    span {
      color: white;
      font-weight: 500;
      font-size: 15px;
    }
  }
}

.EditButton {
  height: 36px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid #9c9c9c;
  padding: 8px 12px;

  p {
    color: #9c9c9c;
    font-size: 14px;
    margin-left: 8px;
  }
}

.EditButtonSecondary {
  background-color: #3a3939;
  height: 36px;
  width: 36px;
  border-radius: 6px;
  //   display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  display: none !important;
}

.ButtonContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  & > div {
    display: flex;
  }
}

.Button {
  background-color: #3a3939;
  border-radius: 6px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  padding: 8px 12px;
  cursor: pointer;

  p {
    color: #9c9c9c;
    font-size: 14px;
    margin-left: 8px;
  }
}

@media(max-width: 1200px) {
  .RightContainer {
    padding: 64px 100px 20px 100px;
  }
}

@media(max-width: 1140px) {
  .RightContainer {
    padding: 50px 60px 20px 60px;
  }
}

@media (max-width: 1024px) {
  .ShareableLink {
    flex-direction: column;
    height: 100%;
  }

  .LeftContainer {
    height: 223px;
    width: 100%;
    justify-content: flex-start;
    padding: 6%;

    img {
      &:nth-child(1) {
        width: 180px;
      }

      &:nth-child(2) {
        width: 180px;
      }
    }
  }

  .LeftInnerContainer {
    p {
      &:nth-child(1) {
        font-size: 22px;
        margin-bottom: 15px;
      }

      &:nth-child(2) {
        font-size: 14px;
      }
    }
  }

  .LinkListItemWrapper {
    width: 430px;
  }

  .LinkListItem {
    width: 428px;
  }

  .RightContainer {
    width: 100%;
    margin-top: 50px;
  }
}

@media (max-width: 830px) {
  .LeftInnerContainer {
    p {
      &:nth-child(1) {
        font-size: 23px;
      }

      &:nth-child(2) {
        font-size: 14px;
      }
    }
  }

  .LinkListItemWrapper {
    width: 430px;
  }

  .LinkListItem {
    width: 428px;
  }
}

@media (max-width: 768px) {
  .ShareableLink {
    flex-direction: column;
    height: 100%;
  }

  .LeftContainer {
    height: 223px;
    width: 100%;
    justify-content: flex-start;
    padding: 6%;

    img {
      &:nth-child(1) {
        width: 130px;
      }

      &:nth-child(2) {
        width: 180px;
      }
    }
  }

  .LeftInnerContainer {
    width: 370px;

    p {
      &:nth-child(1) {
        font-size: 22px;
        margin-bottom: 15px;
      }

      &:nth-child(2) {
        font-size: 14px;
      }
    }
  }

  .RightContainer {
    width: 100%;
    gap: 70px;
    margin-top: 20px;
    padding: 50px 30px 50px 30px;

    .BuyButtonContainer {
      flex-direction: column;
      gap: 20px;

      h1 {
        text-align: center;
        font-size: 20px;
      }

      div {
        justify-content: left;
        height: 37px;
      }
    }
  }

  .LinkList {
    margin-top: -30px;
  }

  .LinkListItemWrapper {
    width: 370px;
  }

  .LinkListItem {
    width: 368px;
  }

  .Link {
    width: 100%;
  }

  .EditButton {
    display: none !important;
  }

  .EditButtonSecondary {
    display: flex !important;
  }
}

@media (max-width: 426px) {
  .RightContainer {
    padding: 40px 30px;
    gap: 80px;

    .BuyButtonContainer {
      gap: 25px;

      div {
        justify-content: center;
      }
    }
  }
}

@media (max-width: 376px) {
  .LinkListItemWrapper {
    width: 320px;
  }

  .LinkListItem {
    width: 318px;
  }

  .LeftInnerContainer {
    width: 310px;

    p {
      &:nth-child(1) {
        font-size: 18px;
        margin-bottom: 11px;
      }

      &:nth-child(2) {
        font-size: 13px;
      }
    }
  }

  .LinkListItemWrapper {
    width: 310px;
  }

  .LinkListItem {
    width: 310px;
  }

  .RightContainer {
    padding: 50px 10px 50px 10px;
  }
  .Link {
    p {
      &:nth-child(1) {
        font-size: 12px;
      }

      &:nth-child(2) {
        font-size: 13px;
      }

      span {
        font-size: 13px;
      }
    }
  }
}
