.AccountUpdateConfirmation {
  position: absolute;
  width: 340px;
  height: fit-content;
  background-color: #363636;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 10px;
  z-index: 160;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
}

.CloseButtonContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  & > div {
    cursor: pointer;
  }
}

.MobileInputWrapper {
  width: 100%;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.25);
  margin-bottom: 10px;
  padding: 1.5px;
}

.MobileInput {
  background-color: #242424;
  width: 100%;
  height: 60px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;

  input {
    width: 100%;
    margin-left: 8px;
    font-weight: 500;
    font-size: 15px;
    background-color: transparent;
    border: none;
    outline: none;
    color: white;
    font-family: inherit;

    &::placeholder {
      color: #8d8d8d;
    }
  }
}

.ViewPassword {
  color: #8a8a8a;
  display: flex;
  align-items: center;
}

.IconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ConfirmationText {
  font-weight: 600;
  font-size: 18px;
  color: white;
  text-align: center;
  line-height: 22px;
  margin: 0;
}

.InfoText {
  font-size: 13px;
  color: #9a9a9a;
  margin-bottom: 16px;
  text-align: center;
  padding: 8px;
}

.ContinueButton {
  color: white;
  border: none;
  margin-top: 15px;
  width: 100%;
  height: 52px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
}
