.AddFieldModal {
  width: 720px;
  height: fit-content;
  background-color: #363636;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  z-index: 160;
  p {
    font-weight: 600;
    font-size: 18px;
    color: white;
  }
}

.LeftContainer {
  position: relative;
  width: 320px;
  background-color: #2c2c2c;
  border-radius: 10px 0 0 10px;
  padding: 24px;
  p {
    margin: 0;
  }
}

.CloseButtonContainerLeft {
  display: none;
  justify-content: flex-end;
  div {
    cursor: pointer;
  }
}

.SelectedIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
  margin-bottom: 24px;
  div {
    height: 21px;
  }
}

.SearchInputWrapper {
  height: 50px;
  width: 100%;
  background-color: #202020;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 20px;
  input {
    width: 100%;
    font-family: inherit;
    background-color: #202020;
    color: #8f8f8f;
    font-weight: 500;
    font-size: 15px;
    border: none;
    margin-left: 8px;
    &::placeholder {
      color: #8f8f8f;
    }
    &:focus {
      outline: none;
    }
  }
}

.IconContainer {
  background-color: #393939;
  width: 100%;
  height: calc(521px - 146px);
  border-radius: 8px;
  padding-left: 6%;
  padding-right: 6%;
  padding-top: 6%;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  & > div {
    display: flex;
    flex-wrap: wrap;
    div {
      height: max-content;
      margin-left: 5px;
      margin-right: 5px;
      margin-bottom: 5px;
      transition: 0.2s;
      cursor: pointer;
      &:hover {
        transform: scale(1.2);
        transition: 0.2s;
      }
    }
  }
}

.MainContainer {
  display: flex;
  flex-direction: column;
  width: 400px;
}

.CloseButtonContainerRight {
  display: flex;
  justify-content: flex-end;
  div {
    cursor: pointer;
    padding-top: 16px;
    padding-right: 16px;
  }
}

.MainContainerContent {
  height: 100%;
  padding: 32px;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  p {
    margin-bottom: 16px
  }
}

.InputContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

}

.InputWrapper {
  width: 100%;
  height: 60px;
  padding: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.25);
  background: linear-gradient(
    142deg,
                  var(--secondary-color) 0%,
    rgba(48, 45, 21, 1) 100%
  );

  .Input {
    width: 100%;
    height: 56px;
    background-color: #242424;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;

    input {
      font-family: inherit;
      background-color: #242424;
      color: #919191;
      font-weight: 500;
      font-size: 15px;
      border: none;

      &::placeholder {
        color: #919191;
      }

      &:focus {
        outline: none;
      }
    }
  }
}

.SaveFieldButton {
  cursor: pointer;
  width: 100%;
  height: 52px;
  border-radius: 8px;
  border: none;
  background: linear-gradient(
    142deg,
                  var(--primary-color) 0%,
                  var(--tertiary-color) 100%
  );
  color: white;
  font-family: inherit;
  font-weight: 500;
  font-size: 14px;
}

@media (max-width: 768px) {
  .AddFieldModal {
    flex-direction: column;
    width: 350px;
    height: fit-content;
  }

  .LeftContainer {
    width: 100%;
    height: 55%;
    padding-left: 24px;
    padding-right: 24px;
    background-color: transparent;
  }
  .CloseButtonContainerLeft {
    display: flex;
    div {
      position: relative;
      right: -10px;
    }
  }

  .SelectedIconContainer {
    margin-top: 0;
    margin-bottom: 15px;
  }

  .SearchInputWrapper {
    margin-bottom: 12px;
  }

  .IconContainer {
    height: 250px;
    background-color: #252525;
    & > div {
      div {
        margin-left: 7px;
        margin-right: 7px;
        margin-bottom: 7px;
      }
    }
  }

  .MainContainer {
    width: 100%;
    height: 45%;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 32px;
  }

  .CloseButtonContainerRight {
    display: none;
  }

  .MainContainerContent {
    height: max-content;
    padding: 0;
  }


  .SaveFieldButton {
    width: 100%;
    margin-top: 40px;
  }
}
