.SecondStep{
  h1{
    color: white;
    text-align: center;
  }
}

.IconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  .Icon {
    color: var(--primary-color);
    width: 70px;
    height: 70px;
  }
}

.Paragraph {
  color: whitesmoke;
  font-size: 14px;
  text-align: center;
  margin-bottom: 30px;

  span{
    color: var(--primary-color);
    font-weight: 500;
    padding: 0;
  }
}

.ValidationCodeInput{
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.CodeBox {
  width: 2.5rem;
  height: 2.5rem;
  margin: 0 0.5rem;
  text-align: center;
  font-size: 1.5rem;
  border: 2px solid white;
  border-radius: 8px;
  background-color: black;
  color: white;
  caret-color: transparent;
}

.CodeBox:focus {
  outline: none;
  border: 2px solid #6f9fed;
}

.AnotherWay {
  text-align: center;
  color: #C5C5C5;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
  display: inherit;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .SecondStep {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}

@media (max-width: 425px) {
  .SecondStep{
    h1{
      font-size:26px;
    }
  }

  .CodeBox {
    width: 2rem;
    height: 2rem;
  }

  .IconWrapper {
    .Icon {
      width: 60px;
      height: 60px;
    }
  }
}
