.ImageCropper {
  position: fixed;
  width: 100%;
  max-width: 400px;
  max-height: 80vh;
  background-color: #363636;
  inset: 0;
  margin: auto;
  border-radius: 10px;
  z-index: 160;
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.CropContainer {
  background-color: black;
  width: 100%;
  height: 60vh;
  flex-grow: 1;
}

.CloseButtonContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 24px;
}

.CloseButtonContainer > button {
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
}

.DoneButton {
  width: 80px;
  height: 36px;
  border-radius: 8px;
  color: white;
  font-weight: 500;
  font-size: 14px;
  border: none;
  cursor: pointer;
  margin: 8px;
  margin-bottom: 28px;
}

.Controls {
  width: 100%;
  padding: 24px 10%;
}

.ZoomRange {
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  background: #555;
  border-radius: 2px;
  outline: none;
}

.ZoomRange::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: var(--thumb-bg-color, var(--primary-color));
  cursor: pointer;
  border: 2px solid #fff;
  box-shadow: 0 0 0 4px rgba(219, 182, 89, 0.2);
  transition: all 0.2s ease-in-out;
}

.ZoomRange::-moz-range-thumb {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: var(--thumb-bg-color, var(--primary-color));
  cursor: pointer;
  border: 2px solid #fff;
  box-shadow: 0 0 0 4px rgba(219, 182, 89, 0.2);
  transition: all 0.2s ease-in-out;
}

.ZoomRange::-webkit-slider-thumb:hover,
.ZoomRange::-moz-range-thumb:hover {
  box-shadow: 0 0 0 6px rgba(219, 182, 89, 0.3);
}

.ZoomRange:active::-webkit-slider-thumb,
.ZoomRange:active::-moz-range-thumb {
  box-shadow: 0 0 0 8px rgba(219, 182, 89, 0.4);
}

@media (max-width: 600px) {
  .ImageCropper {
    width: 100%;
    height: 100%;
    max-height: 80vh;
  }

  .CropContainer {
    height: 50vh;
  }

  .Controls {
    padding: 16px 5%;
  }

  .DoneButton {
    margin-bottom: 16px;
  }
}