.DashboardPage {
  background-color: #151515;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
}

.DropDownPanel {
  height: 234px;
  width: 219px;
  background-color: #484848;
  position: absolute;
  left: 50px;
  top: calc(((90px - 36px) / 2) + 36px + 4px);
  z-index: 2;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
}

.DropDownSectionContainer {
  height: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.DropDownSection {
  height: 67px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.DropDownOption {
  cursor: pointer;
  width: 180px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  svg {
    margin-right: 15px;
  }
  p {
    font-weight: 500;
    font-size: 15px;
    color: #d0d0d0;
  }
}

.Line {
  width: 80%;
  height: 0.5px;
  background-color: #5a5a5a;
}

.CustomToast {
  //background: linear-gradient(142deg, #dab458 0%, #aa7f2b 100%) !important;
  color: white !important;
  text-align: center;
  line-height: 1.5;
  font-size: 16px;
  font-weight: 500;

  svg {
    color: white !important;
  }
}

@media (max-width: 425px) {
  .DashboardPage {
    padding-top: 0;
    align-items: flex-start;
  }
}
