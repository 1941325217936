.ProfileCard {
  cursor: pointer;
  width: 31%;
  height: 230px;
  background-color: #060b05;
  //background-image: url('../../../../../assets/images/card-background-2.png');
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  border: 2px solid;
  border-radius: 12px;
  padding: 1.25rem 1.25rem 1.75rem;
  position: relative;
  margin-bottom: 1rem;
  margin-left: 1%;
  margin-right: 1%;
}

// .BackgroundImage{
//   background-image: url('../../../../../assets/images/card-background-2.png');
// }

.ProfileContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.CardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ViewButton {
  transition: background-color ease-in-out 100ms;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3d3d3d;
  padding: 10px;
  border-radius: 200px;

  &:hover {
    background-color: #6d6d6d;
  }
}

.StatusInactive {
  height: 27px;
  display: flex;
  align-items: center;
  p {
    padding: 6px 10px;
    font-size: 12px;
    font-weight: 600;
    &:nth-child(1) {
      color: #000000;
      border: 1px solid #959490;
      background-color: #959490;
      border-radius: 4px 0px 0px 4px;
    }
    &:nth-child(2) {
      color: #898989;
      border: 1px solid #959490;
      border-radius: 0px 4px 4px 0px;
      border-left-width: 0;
    }
  }
}

.StatusActive {
  height: 27px;
  display: flex;
  align-items: center;

  p {
    padding: 6px 10px;
    font-size: 12px;
    font-weight: 600;
    &:nth-child(1) {
      color: #898989;
      border: 1.5px solid #959490;
      border-radius: 4px 0px 0px 4px;
      border-right-width: 0;
    }
    &:nth-child(2) {
      color: #000000;
      border: 1.5px solid var(--primary-color);
      background-color: var(--primary-color);
      border-radius: 0px 4px 4px 0px;
    }
  }
}

.ProfileInfoContainerActive{
  color: var(--primary-color);
  h2 {
    margin: 0;
    font-weight: 600;
    font-size: 21px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  p {
    margin: 0;
    font-weight: 600;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  span {
    font-weight: 400;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.ProfileInfoContainerInactive {
  color: #C6C6C6;
  h2 {
    margin: 0;
    font-weight: 600;
    font-size: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  p {
    margin: 0;
    font-weight: 600;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  span {
    font-weight: 400;
    font-size: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media (max-width: 768px) {
  .ProfileCard {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}

@media (max-width: 425px) {
  .ProfileCard {
    height: 200px;
  }
}
