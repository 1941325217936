.BackdropContainer {
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  z-index: 150;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}

.Backdrop {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
}
