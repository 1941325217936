.ForgotPasswordBodyContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ForgotPasswordBody {
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 420px;
  padding: 20px 20px;
  border-radius: 10px;
}

.StepperContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-top: 20px;
}

.ActiveStep {
  width: 15px;
  height: 4px;
  background: var(--primary-color);
}

.InactiveStep {
  width: 15px;
  height: 4px;
  border: 1px solid var(--primary-color);
}

.SendButton {
  cursor: pointer;
  height: 52px;
  border-radius: 8px;
  border: none;
  background: var(--primary-color);
  background: linear-gradient(
                  142deg,
                  var(--primary-color) 0%,
                  var(--tertiary-color) 100%
  );
  color: white;
  width: 100%;
  margin-top: 20px;
}

.SpinnerContainer {
  margin-top: 35px;
  height: 52px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1024px) {
  .ForgotPasswordBody {
    width: 400px;
  }
}

@media (max-width: 768px) {
  .ForgotPasswordBodyContainer {
    height: 100%;
  }
  .ForgotPasswordBody {
    height: fit-content;
    width: fit-content;
    justify-content: space-between;
    padding-left: 30px;
    padding-right: 30px;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin:auto;
  }

}

@media (max-width: 500px) {
  .ForgotPasswordBody {
    width: 80%;
  }
}

@media (max-width: 425px) {
  .ForgotPasswordBodyContainer {
    background: black;
  }

  .ForgotPasswordBody {
    //padding-left: 20px;
    //padding-right: 20px;
    width: fit-content;
    height: fit-content;
    justify-content: center;
    top: 0;
  }
}

@media (max-width: 375px) {
  .ForgotPasswordBody {
    //transform: scale(0.8);
  }
}
