.ColorPickerPopup {
  position: absolute;
  width: fit-content;
  height: max-content;
  background-color: #363636 !important;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 10px;
  z-index: 160;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.CloseButtonContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  & > div {
    cursor: pointer;
    margin-right: 10px;
  }
}

.ColorPicker {
  background-color: #363636 !important;
  padding: 7px;

  input {
    outline: none;
    color: #fff !important;
    font-size: 12px !important;
    height: 32px !important;
    border-radius: 8px !important;
  }

  div {
    &:nth-child(2) {
      div {
        &:nth-child(1) {
          div {
            &:nth-child(1) {
              div {
                margin-top: 0 !important;
              }
            }

            &:nth-child(2) {
              div {
                &:nth-child(1) {
                  margin-top: 4px;

                  div {
                    div {
                      div {
                        display: block;
                      }
                    }
                  }
                }

                &:nth-child(2) {
                  display: none;
                }
              }
            }
          }
        }

        &:nth-child(2) {
          div {
            &:nth-child(2) {
              display: none;
            }
          }
        }
      }
    }
  }
}

.SaveButton{
  width: 100%;
  border-radius: 0 0 8px 8px;
  color: white;
  height: 40px;
  font-weight: bold;
  font-size: 15px;
  border: none;
}
