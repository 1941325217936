.NewUser {
  position: absolute;
  width: fit-content;
  height: fit-content;
  display: flex;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  flex-direction: column;
  background: #1F1F1F;
  padding: 32px;
  border-radius: 10px;
  gap: 24px;
}

.TickIcon {
  padding-right: 1rem;
  position: absolute;
  display: flex;
  right: 1px;
}

.SpinnerContainer {
  padding: 0 13.5px;
  display: flex;
  justify-content: center;
  align-items: center;

}

.PhoneInput {
  background-color: #242424 !important;
  width: 100% !important;
  //height: 48px !important;
  //border: none !important;
  //border-radius: 8px !important;
}

.ButtonClass {
  background-color: #242424 !important;
  border-radius: 8px !important;
  border: none !important;
}

.DropdownClass {
  overflow-x: hidden;
  //width: 270px !important;
}

.SearchClass {
  background: #242424 !important;
  width: 95% !important;
  //input {
  //  color: black !important;
  //  background-color: transparent !important;
  //}
}

.CloseButtonContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;

  h1 {
    margin: 0;
    color: white;
    font-size: 20px;
  }

  & > div {
    cursor: pointer;
  }
}

.InputContainer {
  display: flex;
  flex-direction: row;
  gap: 24px;
}

.InputsWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}


.Detail {
  display: flex;
  flex-direction: column;
}

.Label {
  margin: 0;
  margin-bottom: 10px;
  color: #8d8d8d;
  font-size: 13px;
}

.InputWrapper {
  width: 340px;
  height: 100% !important;
  min-height: 50px;
  padding: 1px;
  background: linear-gradient(
                  142deg,
                  var(--secondary-color) 0%,
                  rgba(74, 66, 47, 1) 100%
  );
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Input {
  width: 100%;
  height: 100%;
  background-color: #242424;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  position: relative;

  input {
    width: 100%;
    margin-left: 8px;
    font-weight: 500;
    font-size: 15px;
    background-color: transparent;
    border: none;
    outline: none;
    color: white;
    font-family: inherit;

    &::placeholder {
      visibility: unset;
    }
  }
}

.MobileInputWrapper {
  display: none;
}

.IconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.BottomButtonWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;

  label {
    font-weight: 500;
    font-size: small;
    color: white;
  }

  .ButtonGroup {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 32px;
    align-items: center;
  }

  .Button {
    border: 1px solid rgba(255, 255, 255, 0.3);
    background: transparent;
    padding: 12px 32px;
    color: white;
    border-radius: 8px;
    font-size: 14px;
  }
}

//.BottomButtonWrapper {
//  color: white;
//  align-items: center;
//  display: flex;
//  flex-direction: row;
//  justify-content: space-between;
//  margin-top: 16px;
//
//  .ButtonGroup {
//    display: flex;
//    flex-direction: row;
//    gap: 20px;
//    align-items: center;
//  }
//
//  .Button {
//    border: 1px solid rgba(255, 255, 255, 0.3);
//    background: transparent;
//    padding: 12px 40px;
//    color: white;
//    border-radius: 8px;
//    font-size: 14px;
//  }
//
//  label {
//    font-weight: 500;
//    font-size: small;
//  }
//
//  div {
//    display: flex;
//
//    button:nth-child(3) {
//      background: transparent;
//      border: 1px solid rgba(255, 255, 255, 0.3);
//    }
//
//    button:nth-child(4) {
//      display: none;
//    }
//
//    button:last-child {
//      border: none;
//      margin-left: 15px;
//
//      p {
//        margin: 0;
//      }
//    }
//  }
//}


@media (max-width: 768px) {
  .NewUser {
    padding: 20px;
  }

  .InputsWrapper {
    display: none;
  }

  .MobileInputWrapper {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .BottomButtonWrapper {
    flex-direction: column;
    gap: 24px;

    .ButtonGroup {
      flex-direction: row;
      justify-content: center;
      gap: 24px
    }

    .Button {
      width: 100%;
      padding: 12px 0;
    }
  }
}
