.Button {
  z-index: 100;
  display: none;
  background: transparent;
  position: absolute;
  border: none;
  margin: 0;
  padding: 0;

  svg{
    color: white;
    font-size: 22px;
  }
}

.DrawerContainer{
  display: none!important;
  position: absolute!important;
  height: calc(100vh - 90px)!important;
  background: #0D0D0D!important;
}

.Drawer{
  display: flex;
  flex-direction: column;
}

.DrawerTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  padding-top: 28px;

  h1{
    color: #757D8A;
    font-size: 15px;
    margin: 0;
  }

  svg{
    color: white;
    cursor: pointer;
    font-size: 20px;
  }
}

.DrawerItemSelected{
  padding: 16px 26px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;

  span {
    margin-left: 14px;
  }
}

.DrawerItemNotSelected{
  color: #FAFAFA;
  padding: 16px 26px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;

  span {
    margin-left: 14px;
  }
}

@media (max-width: 1024px) {
  .Button {
    display: unset;
  }

  .DrawerContainer{
    display: unset!important;
  }
}

@media (max-width: 768px) {
  .DrawerContainer {
    top: 68px!important;
    height: calc(100vh - 70px) !important;
  }
}

.Button {
  margin-top: 24px;
  margin-left: 24px;
}

@media (min-width: 425px) {
  .Button {
    margin-top: 28px;
    margin-left: 28px;
  }
}

@media (min-width: 768px) {
  .Button {
    margin-top: 24px;
    margin-left: 40px;
  }
}