.pwaButton {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: black;
  border: 1px solid #959490;
  color: white;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.8;
  }

  &.hidden {
    display: none;
  }

  .icon {
    font-size: 24px;

  }
}
.SmallButton {
}

@media (max-width: 767px) {
  .SmallButton {
    display: none;
  }
}

