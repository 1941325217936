.CreateNewProfileButtonMobile {
  display: none;
  width: 100%;
  height: 50px;
  border: 1px dashed #b0b0b0;
  //border-style: ;
  color: #b0b0b0;
  background-color: transparent;
  margin-bottom: 40px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
}

.Content {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}