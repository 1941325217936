.RegisterFormContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.RegisterForm {
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: auto;
  padding: 20px 20px;
  border-radius: 10px;

  .RegisterCards{
    display: flex;
    flex-direction:row;
    gap:16px;
  }
}

.RegisterButton {
  width: 100%;
  cursor: pointer;
  height: 52px;
  border-radius: 8px;
  border: none;
  background: var(--primary-color);
  background: linear-gradient(
    142deg,
                  var(--primary-color) 0%,
                  var(--tertiary-color) 100%
  );
  color: white;
  margin-top: 15px;
  margin-bottom: 10px;
}

.SpinnerContainer {
  margin-top: 15px;
  margin-bottom: 10px;
  height: 52px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.AlreadyHaveAccount {
  cursor: pointer;
  color: white;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  span {
    color: var(--primary-color);
    text-decoration: underline;
    font-weight: 500;
  }
}

.Input {
  background: var(--secondary-color);
  background: linear-gradient(
                  142deg,
                  var(--secondary-color) 0%,
                  rgb(53, 47, 32) 100%
  );
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px;
  height: 58px;
  margin-bottom: 15px;
  width: 100%;
  position: relative;

  p{
    margin: 0;
    width: fit-content;
    height: 100%;
    display: flex;
    align-items: center;
    background: #151515;
    color: #ababab;
    padding: 0 2px 2px 6px;
    font-size: 13px;
  }

  .PhoneInput {
    background-color: #151515!important;
    width: 100%!important;
    height: 56px!important;
    border: none!important;
    border-radius: 8px!important;
  }

  .ButtonClass{
    background-color: #151515!important;
    border-radius: 8px!important;
    border: none!important;
  }

  .DropdownClass {
    overflow-x: hidden;
    width: 250px !important;
  }

  .SearchClass{
    input{
      color: black!important;
      background-color: white!important;
    }
  }

  input {
    -webkit-appearance: none;
    width: 100%;
    background-color: #151515;
    border: none;
    outline: none;
    border-radius: 0 8px 8px 0;
    height: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    color: white;
  }
}

input:disabled {
  color: darkgrey!important;
  cursor: not-allowed;
}

.IconContainer {
  background-color: #151515;
  padding-left: 1rem;
  color: #8a8a8a;
  height: 100%;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.TickIcon {
  background-color: #151515;
  padding-right: 1rem;
  position: absolute;
  color: #8a8a8a;
  height: 56px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  align-items: center;
  right: 1px;
  justify-content: center;
}

@media (max-width: 1024px) {
  .RegisterForm {
    width: 400px;
  }
  .RegisterButton {
    padding: 15px 0;
  }
}

@media (max-width: 768px) {
  .RegisterFormContainer {
    height: 125%;
  }

  .RegisterButton {
    padding: 15px 0;
  }
  .RegisterForm {
    border-radius: 0;
    height: 100%;
    width: 100%;
    justify-content: flex-start;
    padding-top: 10%;
    padding-left: 20%;
    padding-right: 20%;

    .RegisterCards{
      flex-direction:column;
      gap: 0;
    }
  }
}

@media (max-width: 425px) {
  .RegisterButton {
    padding: 15px 0;
  }
  .RegisterForm {
    padding-top: 15%;
    padding-left: 20px;
    padding-right: 20px;

    .RegisterCards{
      flex-direction:column;
      gap: 0;
    }
  }
}
