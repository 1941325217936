.SidebarOpen {
  padding-left: 280px;
  width: 100%;
  transition-duration: 500ms;
  //white-space: pre;
}

.SidebarClose {
  padding-left: 70px;
  width: 100%;
  transition-duration: 500ms;
  display: flex;
  justify-content: center;
  //white-space: pre;
}

@media (max-width: 1024px) {
  .SidebarOpen {
    padding-left: 0;
  }

  .SidebarClose {
    padding-left: 0;
  }
}

.Wrapper {
  width: 100%;
  height: 100%;
  padding: 28px;
  display: flex;
  flex-direction: column;
  gap: 36px;
}

@media (min-width: 768px) {
  .Wrapper {
    padding: 40px;
  }
}

.TopContainer {
  display: flex;
  justify-content: space-between;

  .Title {
    align-items: center;
    gap: 16px;
    display: flex;

    h1 {
      margin: 0;
      font-size: 24px;
      color: white;
    }

    span {
      background: #404040;
      color: white;
      padding: 6px 14px;
      font-size: 14px;
      border-radius: 4px;
    }
  }

  .SearchContainer {
    display: flex;
    gap: 16px;

    button {
      align-items: center;
      background: #343333;
      padding: 12px 20px;
      font-size: 14px;
      color: #B0B0B0;
      border: none;
      border-radius: 8px;
      display: flex;
      gap: 4px;
    }

    div {
      padding: 12px 4px;
      display: flex;
      background: #151515;
      border: 1px solid #343333;
      align-items: center;
      color: #919191;
      border-radius: 8px;
      gap: 4px;

      svg {
        margin-left: 16px;
      }

      input {
        background: transparent;
        border: none;
        font-family: inherit;
        font-weight: 500;
        color: #919191;
        width: 360px;
        margin-right: 16px;

        &:focus {
          outline: none;
        }
      }
    }
  }
}


.TableContainer {
  overflow-x: auto;
  .ButtonInsideTable {
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 5px 14px;
    color: #BBBBBB;
    font-weight: 500;
    border-radius: 8px;
  }
}

.PaginationNext {
  background-color: #242424;
  color: #BBBBBB;
  padding: 6px 14px;
  border-radius: 6px;
  margin-right: 12px;

  &:hover {
    color: white;
  }
}


@media (max-width: 1100px) {
  .TopContainer {
    margin-top: 16px;

    .SearchContainer {
      gap: 14px;

      div {
        input {
          width: 300px;
          margin-right: 14px;
        }
      }
    }
  }
}


@media (max-width: 768px) {
  .TopContainer {
    overflow-x: auto;
    margin-top: 0;
    flex-direction: column;
    gap: 16px;

    .Title {
      justify-content: center;
      margin: 12px 0;

      h1 {
        font-size: 21px;
      }
    }

    .SearchContainer {
      justify-content: end;

      button {
        font-size: 13px;
      }

      div {
        flex-grow: 1;
      }
    }
  }


}

@media (max-width: 620px) {
  .SearchContainer {
    flex-direction: column-reverse;

    button {
      justify-content: center;
      gap: 8px;
    }

    div {
      height: 45px;
    }
  }
}

@media (max-width: 425px) {
  .Wrapper {
    padding: 24px;
  }

  .TopContainer {
    gap: 16px;

    .Title {
      margin-top: 45px;

      h1 {
        font-size: 20px;
      }

      span {
        padding: 5px 13px;
        font-size: 13px;
      }
    }

    .SearchContainer {
      button {
        font-size: 13px;
      }
    }
  }


}

.SpinnerContainer {
  height: 25vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.NoData {
  height: 50vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  color: rgba(255, 255, 255, 0.6);
  font-size: 20px;
}

