.DashboardContent {
  width: 75%;
}

.ContentHeader {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  //margin-bottom: 25px;
  padding-left: 10px;
  padding-right: 20px;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .QrButton{
    color: white;
    margin-right: 15px;
    width: 28px;
    height: 28px;
    cursor: pointer;
  }
}

.Greeting {
  font-size: 26px;
  font-weight: 600;
  color: white;
  span {
    color: var(--primary-color);
  }
}
.AccountStatusActive {
  cursor: pointer;
  width: 110px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  color: white;
  background-color: #2daf32;
  border: none;
  padding: 6px 10px;
  border-radius: 4px;
  margin-left: 20px;
}

.HighPrivacySwitch {
  display: flex;
  margin-right: 15px;
  padding-left: 10px;
  gap: 10px;
  align-items: center;

  p{
    margin: 0;
    color: white;
    font-size: 12px;
  }
}

.StatusSwitch {
  width: 32px;
  height: 18px;
  border: 1px solid #5b5b5b;
  border-radius: 200px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 3px;
  justify-content: flex-end;
}

.StatusNob {
  background-color: #2DAF32;
  border-radius: 9999px;
  width: 12px;
  height: 12px;
}

.AccountStatusInactive {
  cursor: pointer;
  width: 110px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  color: white;
  background-color: #cd3838;
  border: none;
  padding: 6px 10px;
  border-radius: 4px;
  margin-left: 20px;
}

.SpinnerContainer {
  padding: 6px 10px;
  width: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
}

.CreateProfileButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 42px;
  background-color: #343333;
  border-radius: 8px;
  border: none;
  color: #b0b0b0;
  font-weight: 500;
  font-size: 14px;
  font-family: inherit;
  cursor: pointer;
  transition-duration: 200ms;

  &:hover{
    opacity: 0.8;
  }
}

.Content {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.CreateNewProfileButtonMobile {
  display: none;
  width: 100%;
  height: 50px;
  border: 1px solid #b0b0b0;
  border-style: dashed;
  color: #b0b0b0;
  background-color: transparent;
  margin-bottom: 40px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
}

@media (max-width: 1024px) {
  .DashboardContent {
    width: 90%;
  }
}

@media (max-width: 768px) {
  .DashboardContent {
    width: 90%;
  }
  .ContentHeader {
    display: none;
  }

  .Content {
    padding-top: 30px;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
  }
  .CreateNewProfileButtonMobile {
    display: block;
  }
}
