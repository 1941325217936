.ContactEditPopup {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 325px;
  //height: 465px;
  height: fit-content;
  background-color: #363636;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 10px;
  z-index: 160;
  padding: 10px;
}

.CloseButtonContainer {
  display: flex;
  justify-content: flex-end;
}

.DetailContainer {
  display: flex;
  flex-direction: column;
  padding: 0 10px;
}

.Detail {
  display: flex;
  justify-content: space-between;
  height: 20px;
  align-items: center;
  margin-top: 15px;

  p {
    font-weight: 400;
    font-size: 14px;
    color: #7a7a7a;
  }
  input,
  textarea {
    color: #919191;
    border: none;
    background: #2d2d2d;
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    font-weight: 500;
    padding: 2px 0 0 10px;

    font-family: inherit;
    resize: none;

    &:focus {
      outline: none;
    }
  }
}

.SpinnerContainer {
  padding: 6px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ButtonContainer {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  margin-top: 30px;
  margin-bottom: 5px;

  button {
    width: 100%;
    height: 41px;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;

    p{
      margin: 0;
    }

    &:nth-child(1) {
      border: 1px solid #554e3d;
      background-color: #545454;
      color: var(--primary-color);
    }

  }
}
