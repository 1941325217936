.ProfileLimitReached {
  position: absolute;
  width: 335px;
  height: 310px;
  background-color: #363636;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 10px;
  z-index: 160;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.CloseButtonContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  svg{
    cursor: pointer;
  }
}

.LockImage {
  width: 60px;
}

.MainText {
  font-weight: 600;
  font-size: 18px;
  color: white;
  text-align: center;
  line-height: 22px;

  margin-top: 20px;
}

.InfoText {
  font-size: 13px;
  color: #9a9a9a;
  margin-top: 0;
  text-align: center;
}

.GotItButton {
  color: white;
  border: none;
  width: 275px;
  height: 52px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  margin-top: 16px;
  margin-bottom: 16px;
}
