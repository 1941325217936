.Button {
  border-radius: 8px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 100%;
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 1rem;
}

.Primary {
  background: var(--primary-color);
  background: linear-gradient(
    142deg,
                  var(--primary-color) 0%,
                  var(--tertiary-color) 100%
  );
  color: white;
}

.Secondary {
  background-color: #372d16;
  color: white;
  span {
    color: var(--primary-color);
  }
}

.Outline {
  background-color: transparent;
  border: 1px solid #535353;
  color: var(--primary-color);
}

.Dotted {
  background-color: transparent;
  border: 1px dashed #787878;
  color: #787878;
}
