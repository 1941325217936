.RegisterHeaderContainer {
  height: 100%;
  background: linear-gradient(
    114.54deg,
    #000000 0.12%,
    rgba(219, 182, 89, 0.36) 100%
  );
}

.RegisterHeader {
  text-align: right;
  height: 255px;
  position: relative;
  width: 375px;
  padding-top: 9%;
  padding-right: 6%;

  h1 {
    font-weight: 600;
    font-size: 17px;
    color: white;
    font-family: 'Michroma', sans-serif;
    margin: 0;
  }
}

.TopBox {
  background-color: #080d07;
  width: 60%;
  height: 140px;
  position: absolute;
  left: 0;
  bottom: 0;
  border-top-right-radius: 14px;
  box-shadow: -18px 6px 15px rgba(0, 0, 0, 0.1);
  border-right: white 1px solid;
  border-top: white 1px solid;
  z-index: 5;
  padding-top: 5%;
  padding-right: 5%;
  img {
    width: 160px;
  }
}

.MiddleBox {
  background-color: #080d07;
  width: 70%;
  height: 90px;
  position: absolute;
  left: 0;
  bottom: 0;
  border-top-right-radius: 14px;
  z-index: 3;
  opacity: 0.5;
  border-right: white 1px solid;
  border-top: white 1px solid;
}

.BottomBox {
  background-color: #080d07;
  width: 80%;
  height: 50px;
  position: absolute;
  left: 0;
  bottom: 0;
  border-top-right-radius: 14px;
  z-index: 2;
  opacity: 0.2;
  border-right: white 1px solid;
  border-top: white 1px solid;
}

@media (max-width: 1024px) {
  .RegisterHeader {
    width: 300px;
    h1 {
      font-size: 14px;
    }
  }
}

@media (max-width: 768px) {
  .RegisterHeaderContainer {
    height: max-content;
  }

  .RegisterHeader {
    width: 100%;
  }
}
