.FirstStep {
  h1 {
    color: white;
    text-align: center;
  }
}

.IconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  .Icon {
    color: var(--primary-color);
    width: 70px;
    height: 70px;
  }
}

.Paragraph {
  color: whitesmoke;
  font-size: 14px;
  text-align: center;
  margin-bottom: 30px;

  span {
    color: var(--primary-color);
    font-weight: 500;
    padding: 0;
  }
}

.AnotherWay {
  text-align: center;
  color: #C5C5C5;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
  display: inherit;
}

@media (max-width: 768px) {
  .FirstStep {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}

@media (max-width: 425px) {
  .FirstStep {
    h1 {
      font-size: 26px;
    }
  }
  .IconWrapper {
    .Icon {
      width: 60px;
      height: 60px;
    }
  }
}
