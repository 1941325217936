.TeamsContainer{
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
  position: relative;
  height: calc(100vh - 147px);
  display: flex;
  overflow-x: hidden;
}

@media (max-width: 768px) {
  .TeamsContainer {
    flex-direction: column;
    height: 100vh;
  }
}
