.TryAnotherWayPopup {
  position: absolute;
  width: 350px;
  height: 220px;
  background-color: #363636;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 10px;
  z-index: 160;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.CloseButtonContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  & > div {
    cursor: pointer;
  }
}

.ConfirmationText {
  font-weight: 600;
  font-size: 18px;
  color: white;
  text-align: center;
  line-height: 22px;
  margin-top: 10px;
}

.InfoText {
  font-size: 13px;
  color: #9a9a9a;
  margin-top: 0;
  text-align: center;
}

