.Backdrop {
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 150;
}

.PrimeMenu {
  position: fixed;
  top: 0;
  bottom: 0;
  // width: 75%;
  width: 320px;
  right: -320px;
  padding: 0 16px;
  background-color: #2c2c2c;
  z-index: 160;
  transition: 0.5s;
}

.PrimeMenuOpen {
  right: 0;
  transition: 0.5s;
}

.MenuHeader {
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 16px;
  margin-top: 16px;
  margin-bottom: 8px;

  p {
    font-weight: 600;
    font-size: 21px;
    color: white;
    margin: 0;
  }
}

.CloseButtonContainer {
  background-color: #3f3f3f;
  width: 33px;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

.Items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 16px;
  gap: 16px;
  justify-content: center;
  align-items: center;

  .TeamsContain {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .Option {
    height: 100px;
    //width: 100%;
    background-color: #303030;
    border: 1px solid #7d6320;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 10px 3px rgba(0, 0, 0, 0.1);

    p {
      color: var(--primary-color);
      font-size: 14px;
      margin-bottom: 5px;
    }
  }
}

.LinkContainer {
  margin-top: 8%;
  display: flex;
  justify-content: center;
  cursor: pointer;

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #353535;
    height: 40px;
    gap: 8px;
    border-radius: 6px;
    padding-left: 5%;
    padding-right: 3%;

    p {
      font-size: 13px;
      font-weight: 500;
      color: #c7c7c7;
    }
  }
}


.PWAButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 8px;
  padding: 12px 18px;
  background-color: #3F3F3F;
  border: none;
  color: white;
  margin: 32px auto;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: darken(#514F66, 10%);
  }

  &:active {
    transform: scale(0.98);
  }

  p {
    font-weight: bold;
    margin: 0;
  }

  svg {
    font-size: 24px;
  }
}


.LogOutButton {
  border: none;
 // margin: 60px auto;
  padding: 10px 0 ;
  text-align: center;
  font-weight: 600;
  color: #CD3838;
  margin-top: 16px;
}
