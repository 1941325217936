.AddNewContactPopup {
  position: absolute;
  width: 335px;
  height: max-content;
  background-color: #363636;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 10px;
  z-index: 160;
  padding: 10px;
  padding-left: 18px;
  padding-right: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.CloseButtonContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  & > div {
    cursor: pointer;
  }
}

.Title {
  color: white;
  font-weight: 600;
  font-size: 18px;
  font-family: inherit;
  margin-top: 10px;
  margin-bottom: 20px;
}

.InputWrapper {
  width: 100%;
  height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    142deg,
                  var(--secondary-color) 0%,
    rgba(74, 66, 47, 1) 100%
  );
  margin-bottom: 10px;
  border-radius: 8px;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.25);
  & > div {
    position: relative;
    width: calc(100% - 2px);
    height: 56px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding-left: 3.5%;
    padding-right: 3.5%;
    background-color: #242424;
    input,
    textarea {
      color: #919191;
      border: none;
      width: 100%;
      background-color: transparent;
      font-weight: 500;
      font-size: 15px;
      font-family: inherit;
      resize: none;

      &:focus {
        outline: none;
      }
      &::placeholder {
        color: #919191;
      }
    }
    select {
      width: 100%;
      height: 100%;
      border: none;
      border-radius: 8px;
      background-color: #242424;
      color: #919191;
      font-weight: 500;
      font-size: 15px;
      font-family: inherit;
      padding-left: 3.5%;
      padding-right: 3.5%;
      appearance: none;
  
      &:focus {
        outline: none;
      }
    }
  }
}

.IconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  margin-right: 7px;
}

.PhoneInput {
  background-color: #242424!important;
  width: 100%!important;
  height: 48px!important;
  border: none!important;
  border-radius: 8px!important;
}

.ButtonClass{
  background-color: #242424!important;
  border-radius: 8px!important;
  border: none!important;
}

.DropdownClass {
  overflow-x: hidden;
  width: 270px !important;
}

.SearchClass{
  input{
    color: black!important;
    background-color: white!important;
    width: 95%!important;
  }
}

.SaveButton {
  cursor: pointer;
  background: linear-gradient(91.3deg, var(--primary-color) 1.61%, #a97e2a 100%);
  height: 52px;
  width: 100%;
  border-radius: 8px;
  border: none;
  color: white;
  font-weight: 500;
  font-size: 14px;
  font-family: inherit;
  margin-bottom: 15px;
  margin-top: 10px;
}

.SpinnerContainer {
  margin-bottom: 15px;
  margin-top: 10px;
  height: 52px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
