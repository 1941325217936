.LoginHeaderContainer {
  height: 100%;
  background: linear-gradient(
    114.54deg,
    #000000 0.12%,
    rgba(171, 171, 171, 0.36) 100%
  );
}

.LoginHeader {
  height: 255px;
  position: relative;
  width: 375px;
  padding-top: 9%;
  padding-left: 6%;

  h1 {
    font-weight: 600;
    font-size: 22px;
    color: var(--primary-color);
    margin: 0;
  }
}

.TopBox {
  background: var(--quaternary-color);
  background: linear-gradient(
    90deg,
                  var(--quaternary-color) 0%,
                  var(--quinary-color) 100%
  );
  width: 60%;
  height: 140px;
  position: absolute;
  right: 0;
  bottom: 0;
  border-top-left-radius: 14px;
  box-shadow: -18px 6px 15px rgba(0, 0, 0, 0.1);
  border-left: white 1px solid;
  border-top: white 1px solid;
  z-index: 5;
  padding-top: 5%;
  padding-left: 5%;
  img {
    width: 160px;
  }
}

.MiddleBox {
  background: var(--quaternary-color);
  background: linear-gradient(
    90deg,
                  var(--quaternary-color) 0%,
                  var(--quinary-color) 100%
  );
  width: 70%;
  height: 90px;
  position: absolute;
  right: 0;
  bottom: 0;
  border-top-left-radius: 14px;
  z-index: 3;
  opacity: 0.5;
  border-left: white 1px solid;
  border-top: white 1px solid;
}

.BottomBox {
  background: var(--quaternary-color);
  background: linear-gradient(
    90deg,
                  var(--quaternary-color) 0%,
                  var(--quinary-color) 100%
  );
  width: 80%;
  height: 50px;
  position: absolute;
  right: 0;
  bottom: 0;
  border-top-left-radius: 14px;
  z-index: 2;
  opacity: 0.2;
  border-left: white 1px solid;
  border-top: white 1px solid;
}

@media (max-width: 1024px) {
  .LoginHeader {
    width: 300px;
    h1 {
      font-size: 20px;
    }
  }
}

@media (max-width: 768px) {
  .LoginHeaderContainer {
    height: max-content;
  }

  .LoginHeader {
    width: 100%;
  }
}
