.ChangePasswordPopup {
  position: absolute;
  width: 340px;
  height: max-content;
  background-color: #363636;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 10px;
  z-index: 160;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.CloseButtonContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  & > div {
    cursor: pointer;
  }
}

.Title {
  color: white;
  font-weight: 600;
  font-size: 18px;
  font-family: inherit;
  margin-top: 0;
  margin-bottom: 24px;
}

.ViewPassword {
  color: #8a8a8a;
  display: flex;
  align-items: center;
}


.InputWrapper {
  width: 100%;
  height: 48px;
  padding: 1.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  border-radius: 8px;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.25);
  & > div {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding-left: 3.5%;
    padding-right: 3.5%;
    background-color: #242424;

    svg {
      color: #919191;
    }

    input,
    textarea {
      color: #919191;
      border: none;
      width: 100%;
      background-color: transparent;
      font-weight: 500;
      font-size: 15px;
      font-family: inherit;
      resize: none;

      &:focus {
        outline: none;
      }
      &::placeholder {
        color: #919191;
      }
    }
  }
}

.IconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  margin-right: 7px;
}


.SaveButton {
  cursor: pointer;
  height: 52px;
  width: 100%;
  border-radius: 8px;
  border: none;
  color: white;
  font-weight: 500;
  font-size: 14px;
  font-family: inherit;
  margin-bottom: 4px;
  margin-top: 16px;
}

.SpinnerContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
