/*
.Analytics {
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
  position: relative;
  height: calc(100vh - 90px);
  display: flex;
  overflow-x: hidden;
}

.LeftContainer {
  background-color: var(--primary-color);
  width: 25%;
  height: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;

  img {
    &:nth-child(1) {
      position: absolute;
      width: 230px;
      top: 0;
      left: 0;
    }

    &:nth-child(2) {
      position: absolute;
      width: 280px;
      bottom: 0;
      right: 0;
      z-index: 10;
    }
  }

  p {
    font-weight: 600;
    font-size: 24px;
    color: black;
  }
}

.RightContainer {
  background-color: #151515;
  width: 75%;
  height: 100%;
  display: flex;
  flex-direction: column;

}

.CardContainer{
  width: 100%;
  display: flex;
  padding: 60px 70px 30px;
  gap: 30px;

  .Card{
    flex: 1;
    background: #1e1e1e;
    border-radius: 12px;
    color: rgba(255,255,255,0.5);
    padding: 20px;
    text-align: center;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,.03), 0 1px 6px -1px rgba(0,0,0,.02), 0 2px 4px 0 rgba(0,0,0,.02);
  }
}

.ChartContainer{
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1b1b1b;
  border-radius: 12px;
  margin: 0 70px;

  canvas{
    height: 100%!important;
  }
}

@media (max-width: 1440px) {
  .LeftContainer {
    img {
      &:nth-child(1) {
        width: 210px;
      }

      &:nth-child(2) {
        width: 250px;
      }
    }
    p {
      font-size: 20px;
    }
  }
}

@media (max-width: 1100px) {
  .LeftContainer {
    p {
      font-size: 18px;
    }
  }
}

@media (max-width: 1280px) {
  .CardContainer {
    padding: 50px 35px 30px;
  }

  .ChartContainer{
    margin: 0 40px;
    height: unset;
  }
}

@media (max-width: 1024px) {
  .LeftContainer{
    display: none;
  }

  .ChartContainer{
    height: 400px;
  }

  .RightContainer {
    width: 100%;

    p{
      font-size: 25px;
    }
  }
}

@media (max-width: 768px) {
  .Analytics {
    flex-direction: column;
    height: 100%;
  }

  .ChartContainer{
    height: unset;
    width: 90%;
    padding: 10px;
  }

  .CardContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    z-index: 11;

    .Card:nth-child(1),
    .Card:nth-child(2) {
      grid-row: 1;
    }

    .Card:nth-child(3),
    .Card:nth-child(4) {
      grid-row: 2;
    }
  }

  .LeftContainer {
    height: 180px;
    width: 100%;
    justify-content: flex-start;
    padding: 6%;
    display: block;

    p {
      font-size: 22px;
      text-align: center;
    }
  }

  .RightContainer {
    width: 100%;
    height: calc(100vh - 178px);
    justify-content: center;
    align-items: center;
    padding-bottom: 80px;

    p{
      font-size: 25px;
      margin-top: 0;
    }
  }
}

@media (max-width: 425px) {
  .LeftContainer{
    height: 150px;

    img {
      &:nth-child(1) {
        width: 100px;
      }

      &:nth-child(2) {
        width: 110px;
      }
    }
  }

  .RightContainer{
    p{
      font-size: 22px;
    }
  }
}

@media (max-width: 320px) {
  .RightContainer{
    p{
      font-size: 20px;
    }
  }
}

*/

.Analytics {
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
  position: relative;
  height: calc(100vh - 90px);
  display: flex;
  overflow-x: hidden;
}

.LeftContainer {
  background-color: var(--primary-color);
  width: 25%;
  height: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;

  img {
    &:nth-child(1) {
      position: absolute;
      width: 230px;
      top: 0;
      left: 0;
    }

    &:nth-child(2) {
      position: absolute;
      width: 280px;
      bottom: 0;
      right: 0;
      z-index: 10;
    }
  }

  p {
    font-weight: 600;
    font-size: 24px;
    color: black;
  }
}

.RightContainer {
  background-color: #151515;
  width: 75%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;

  p{
    color: white;
    text-align: center;
    font-size: 28px;
    font-weight: 600;
    margin-top: 0;
    line-height: 1.5;
  }
}

.GraphImage{
  width: 500px;
  margin-top: 50px;
}

@media (max-width: 1440px) {
  .LeftContainer {
    img {
      &:nth-child(1) {
        width: 210px;
      }

      &:nth-child(2) {
        width: 250px;
      }
    }
    p {
      font-size: 20px;
    }
  }
}

@media (max-width: 1100px) {
  .LeftContainer {
    p {
      font-size: 18px;
    }
  }
}


@media (max-width: 1024px) {
  .LeftContainer{
    img {
      &:nth-child(1) {
        width: 170px;
      }

      &:nth-child(2) {
        width: 170px;
      }
    }
  }

  .RightContainer {
    width: 100%;

    p{
      font-size: 25px;
    }
  }

  .GraphImage{
    width: 400px;
  }
}

@media (max-width: 890px) {
  .LeftContainer{
    width: 30%;
  }
  .RightContainer{
    width: 70%;
  }
}

@media (max-width: 768px) {
  .Analytics {
    flex-direction: column;
    height: 100%;
  }

  .LeftContainer {
    height: 180px;
    width: 100%;
    justify-content: flex-start;
    padding: 6%;
    display: block;

    p {
      font-size: 22px;
      text-align: center;
    }
  }

  .RightContainer {
    width: 100%;
    height: calc(100vh - 178px);
    justify-content: center;
    align-items: center;

    p{
      font-size: 25px;
      margin-top: 0;
    }
  }

  .GraphImage{
    width: 300px;
  }
}

@media (max-width: 425px) {
  .LeftContainer{
    height: 150px;

    img {
      &:nth-child(1) {
        width: 100px;
      }

      &:nth-child(2) {
        width: 110px;
      }
    }
  }

  .RightContainer{
    p{
      font-size: 22px;
    }
  }
  .GraphImage{
    width: 280px;
  }
}

@media (max-width: 320px) {
  .RightContainer{
    p{
      font-size: 20px;
    }
  }
  .GraphImage{
    width: 250px;
  }
}
