.LoginFormContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.LoginForm {
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 420px;
  padding: 20px 20px;
  border-radius: 10px;
}

.ForgotPassword {
  color: white;
  opacity: 0.5;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  width: 100%;
  margin-top: 10px;
  text-decoration: underline;
  cursor: pointer;
}

.LoginButton {
  cursor: pointer;
  height: 52px;
  border-radius: 8px;
  border: none;
  background: var(--primary-color);
  background: linear-gradient(
    142deg,
                  var(--primary-color) 0%,
                  var(--tertiary-color) 100%
  );
  color: white;
  margin-top: 35px;
}

.SpinnerContainer {
  margin-top: 35px;
  height: 52px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.RegisterNowContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  cursor: pointer;
  p {
    font-size: 14px;
    &:nth-child(1) {
      color: white;
    }
    &:nth-child(2) {
      color: var(--primary-color);
      font-weight: 500;
      text-decoration: underline;
      margin-left: 5px;
    }
  }
}

@media (max-width: 1024px) {
  .LoginForm {
    width: 400px;
  }
}

@media (max-width: 768px) {
  .LoginFormContainer {
    height: 100%;
  }
  .LoginForm {
    border-radius: 0;
    height: 100%;
    width: 100%;
    justify-content: flex-start;
    padding-top: 10%;
    padding-left: 20%;
    padding-right: 20%;
  }
}

@media (max-width: 560px) {
  .LoginForm {
    padding-left: 10%;
    padding-right: 10%;
  }
}

@media (max-width: 440px) {
  .LoginForm {
    padding-top: 15%;
    padding-left: 20px;
    padding-right: 20px;
  }
}
