.PrimeTabMenu {
  height: 57px;
  background-color: #222222;
  display: flex;
  justify-content: center;
  align-items: center;
}

.TabOptions {
  display: flex;
  height: 100%;
  justify-content: space-between;
  width: 100%;
  max-width: 850px;

  div {
    width: fit-content;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.TabOptionActive {
  border-bottom: 2px solid #757575;
  img {
    filter: brightness(100);
  }
  svg {
    color: white;
  }
  p {
    font-size: 14px;
    font-weight: 500;
    color: white;
    margin-left: 10px;
  }
}

.TabOptionInactive {
  .SVG {
    color: var(--primary-color);
  }
  p {
    font-size: 14px;
    font-weight: 500;
    //color: var(--primary-color);
    margin-left: 10px;
  }
}

@media (max-width: 950px) {
  .PrimeTabMenu {
    div {
      margin-left: 24px;
      margin-right: 24px;
    }
  }
}

@media (max-width: 875px) {
  .PrimeTabMenu {
    div {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
}

@media (max-width: 768px) {
  .PrimeTabMenu {
    display: none;
  }
}
