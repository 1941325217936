.Categories {
  height: 40px;
  display: flex;
  align-items: center;
  // padding-left: calc(6% - 5px);
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }

  margin-bottom: 32px;
  width: 50%;
  div {
    cursor: pointer;
  }
}

.Category {
  min-width: max-content;
  height: 38px;
  border: 1px solid #595959;
  color: #595959;
  display: flex;
  align-items: center;
  // padding: 18px 20px;
  padding: 10px 20px;
  border-radius: 200px;
  margin-right: 10px;
  p {
    // font-size: 15px;
    font-size: 13px;
    font-weight: 500;
  }
}

.CategoryActive {
  min-width: max-content;
  height: 38px;
  border: 1px solid #595959;
  background-color: #3d3d3d;
  color: #bfbfbf;
  display: flex;
  align-items: center;
  // padding: 18px 20px;
  padding: 10px 20px;
  border-radius: 200px;
  margin-right: 10px;
  p {
    // font-size: 15px;
    font-size: 13px;
    font-weight: 500;
  }
}

@media (max-width: 1440px) {
  .Categories {
    width: 60%;
  }
}

@media (max-width: 1200px) {
  .Categories {
    width: 70%;
  }
}

@media (max-width: 1024px) {
  .Categories {
    width: 85%;
  }
}

@media (max-width: 768px) {
  .Categories {
    width: 100%;
    padding-left: 5%;
  }
}
