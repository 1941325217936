.ContactDetailPopup {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 325px;
  //height: 465px;
  height: fit-content;
  background-color: #363636;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 10px;
  z-index: 160;
  padding: 16px;
}

.CloseButtonContainer {
  display: flex;
  justify-content: flex-end;
}

.SpinnerContainer {
  padding: 16px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PopupHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.NameInitials {
  width: 59px;
  height: 59px;
  background-color: #545454;
  border-radius: 200px;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-size: 20px;
    font-weight: 500;
    color: var(--primary-color);
  }
}

.Name {
  font-weight: 600;
  color: var(--primary-color);
  font-size: 22px;
  margin-top: 12px;
  text-align: center;
}

.DetailContainer {
  display: flex;
  flex-direction: column;
  padding: 0 10px;
}

.Detail {
  display: flex;
  justify-content: space-between;
  height: 20px;
  align-items: center;
  margin-top: 15px;

  p {
    font-weight: 400;
    font-size: 14px;

    &:nth-child(1) {
      color: #7a7a7a;
    }

    &:nth-child(2) {
      color: #e6e6e6;
    }
  }
}

.ButtonContainer {
  display: flex;
  gap: 16px;
  justify-content: space-between;
  padding: 0 10px;
  margin-top: 30px;
  margin-bottom: 5px;

  button {
    width: 100%;
    height: 40px;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;

    &:nth-child(1) {
      border: 1px solid #554e3d;
      background-color: #545454;
      color: var(--primary-color);
    }

    &:nth-child(2) {
      border: 1px solid #515151;
      background-color: transparent;
      color: #aeaeae;
    }
  }

  .DeleteConfirmation {
    display: flex;
    width: 135px;
    height: 41px;
    //  border: 1px solid #C5C5C5;
    background: #C5C5C5;
    border-radius: 8px;
    font-weight: 500;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;

    div {
      width: calc(135px / 2);
      display: flex;
      height: 41px;
      align-items: center;
      font-size: 22px;
      justify-content: center;

      &:nth-child(1) {
        border-right: 1px solid #363636;
        background: #FE0000;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      &:nth-child(2) {
      }
    }
  }

  .DeleteConfirmation {
    div {
      &:nth-child(1):hover {
        font-size: 30px;
        transition: 0.4s ease-in-out;
      }
      &:nth-child(2):hover {
        font-size:30px;
        transition: 0.4s ease-in-out;
      }
    }
  }
}

.SaveToPhoneBtn {
  font-size: 14px;
  font-weight: 500;
  margin: 10px 10px 0 10px;
  border: none;
  border-radius:8px;
  padding: 12px 0;
  color: white;
}
