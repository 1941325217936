.Contacts {
  width: 100%;
  margin: 0;
  position: relative;
}

.DropDownPanel {
  height: 234px;
  width: 219px;
  background-color: #484848;
  position: absolute;
  left: 0;
  top: calc(((68px - 36px) / 2) + 36px + 4px);
  z-index: 2;
  left: 1.25rem;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
}

.DropDownSectionContainer {
  height: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.DropDownSection {
  height: 67px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.DropDownOption {
  width: 180px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  svg {
    margin-right: 15px;
  }

  p {
    font-weight: 500;
    font-size: 15px;
    color: #d0d0d0;
  }
}

.Line {
  width: 80%;
  height: 0.5px;
  background-color: #5a5a5a;
}

.TopContainer {
  height: 180px;
  background-color: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;

  img {
    position: absolute;

    &:nth-child(1) {
      left: 0;
      top: 0;
      height: 180px;
    }

    &:nth-child(2) {
      right: 0;
      bottom: 0;
      height: 160px;
    }

    &:nth-child(3) {
      display: none;
    }
  }
}

.TopInnerContainer {
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    color: black;
    font-weight: 600;
    font-size: 24px;
    z-index: 1;
    width: max-content;
  }

  .ButtonContainer {
    display: flex;
    justify-content: center;
    width: max-content;
    z-index: 1;
    opacity: 0.8;
    transition-duration: 200ms;

    &:hover {
      opacity: 1;
    }

    button {
      cursor: pointer;
      border: none;
      width: 200px;
      height: 42px;
      border-radius: 8px;
      font-size: 14px;
      font-weight: 500;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: inherit;
      background-color: #a3853a;
    }
  }
}

.SearchInputContainer {
  width: 50%;
  height: 56px;
  background-color: #242424;
  position: absolute;

  top: calc(180px - 28px);
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 8px;
  border: 1px solid var(--primary-color);
  display: flex;
  align-items: center;
  padding-left: 20px;
  z-index: 1;

  input {
    color: #919191;
    border: none;
    background-color: #242424;
    height: 50%;
    width: 84%;
    font-weight: 500;
    font-family: inherit;
    font-size: 15px;

    &:focus {
      outline: none;
    }
  }

  svg {
    margin-right: 10px;
  }
}

.BottomContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 32px;
  position: relative;
  padding-top: 56px;
  overflow: hidden;
}

.BottomInnerContainer {
  width: 50%;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.NoContactsMessage {
  color: white;
  text-align: center;
  font-weight: normal;
  margin: auto;
  width: 100%;
}

@media (max-width: 1440px) {
  .TopInnerContainer {
    width: 60%;
  }
  .SearchInputContainer {
    width: 60%;
  }

  .BottomInnerContainer {
    width: 60%;
  }
}

@media (max-width: 1200px) {
  .TopInnerContainer {
    width: 70%;

    p {
      font-size: 22px;
    }

    .ButtonContainer {
      button {
        width: 150px;
      }
    }
  }
  .SearchInputContainer {
    width: 70%;
  }

  .BottomInnerContainer {
    grid-template-columns: repeat(2, 1fr);
    width: 70%;
  }
}

@media (max-width: 1024px) {
  .TopInnerContainer {
    width: 85%;
  }
  .SearchInputContainer {
    width: 85%;
  }
  .BottomInnerContainer {
    width: 85%;
  }
}

@media (max-width: 768px) {
  .TopContainer {
    height: 223px;

    img {
      &:nth-child(1) {
        display: none;
      }

      &:nth-child(2) {
        display: none;
      }

      &:nth-child(3) {
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        height: 210px;
      }
    }
  }

  .TopInnerContainer {
    flex-direction: column;
    width: 90%;
    align-items: flex-start;

    p {
      width: 100%;
      font-size: 20px;
    }
  }

  .ButtonContainer {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 45px;
    justify-content: center;
  }

  .SearchInputContainer {
    width: calc(88% + 10px);
    top: calc(68px + 223px - 28px);
    padding-left: 20px;

    svg {
      margin-right: 10px;
    }
  }

  .BottomContainer {
    //min-height: 550px;
    background-color: #212121;
  }

  .BottomInnerContainer {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    margin: 0;
    width: 90%;
  }
}

@media (max-width: 425px) {
  .Contacts {
    width: 100%;
  }
}

@media (max-width: 375px) {
  .TopContainer {
    height: 200px;

    img {
      &:nth-child(3) {
        height: 190px;
      }
    }
  }
  .TopInnerContainer {
    p {
      font-size: 18px;
    }
  }
  .ButtonContainer {
    button {
      width: 140px !important;
    }
  }

  .SearchInputContainer {
    height: 50px;

    top: calc(68px + 200px - 25px);
  }
}
