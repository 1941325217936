.SidebarWrapper {
  height: calc(100vh - 90px);
  background: #0D0D0D;
  display: flex;
  flex-direction: column;
  width: 280px;
  transition: width 500ms;
  position: fixed;
  z-index: 99;
  gap: 8px;
  overflow: hidden;
}

.SidebarWrapperCollapsed {
  width: 70px;
  transition: width 500ms;
}

.Head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  width: 100%;
  padding: 24px;
}

.Head h1 {
  color: #757d8a;
  font-size: 15px;
  margin: 0;
  transition: opacity 300ms;
}

.Head .ToggleButton {
  color: white;
  font-size: 18px;
  cursor: pointer;
}

.MenuItems,
.ItemSelected {
  display: flex;
  height: 56px;
  width: 100%;
  flex-direction: row;
  align-items: center;
  padding-left: 28px;
  gap: 16px;
  font-weight: 500;
  font-size: 15px;
  color: #fafafa;
  cursor: pointer;
  transition: background 300ms, padding-left 300ms, color 300ms;
}

.MenuItems:hover {
  background: rgba(45, 45, 45, 0.53);
}

.ItemSelected {
  background: var(--primary-color);
  color: black;
  height: 60px;
}

.SidebarWrapperCollapsed .Head h1,
.SidebarWrapperCollapsed .MenuItems span,
.SidebarWrapperCollapsed .ItemSelected span {
  display: none;
}

.SidebarWrapperCollapsed .MenuItems,
.SidebarWrapperCollapsed .ItemSelected {
  padding-left: 0;
  justify-content: center;
}

/* Hide the entire sidebar below 1024px */
@media (max-width: 1024px) {
  .SidebarWrapper {
    display: none;
  }
}