.HighPrivacyChangerPopup {
  position: absolute;
  width: 480px;
  height: fit-content;
  background-color: #363636;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 10px;
  z-index: 1000;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.CloseButtonContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  & > div {
    cursor: pointer;
  }
}

.ConfirmationText {
  font-weight: 600;
  font-size: 18px;
  color: white;
  text-align: center;
  margin-top: 0;
  margin-bottom: 16px;
}

.InnerContainer {
  display: flex;
  height: fit-content;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  border-bottom: 1px solid #474747;
  padding: 16px;
  gap: 8px;
  margin: 0;

  img {
    width: 120px;
    margin-top: -8px;
  }
}

.InfoText {
  font-size: 13px;
  color: #9a9a9a;
  margin-top: 0;

}

.ConfirmButton {
  background: linear-gradient(
                  142deg,
                  var(--primary-color) 0%,
                  var(--tertiary-color) 100%
  );
  color: white;
  border: none;
  width: 100%;
  height: 48px;
  border-radius: 25px;
  font-weight: 500;
  font-size: 14px;
  margin-top: 24px;
  margin-bottom: 4px;
}

@media (max-width: 768px) {
  .HighPrivacyChangerPopup {
    width: 340px;
  }

  .InnerContainer {
    flex-direction: column;

    img {
      width: 80px;
      margin-bottom: 10px;
    }
  }

  .InfoText {
    text-align: center;
  }
}
