.ForgotPasswordPage {
  background-color: #1e1e1e;
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

@media (max-width: 768px) {
  .ForgotPasswordPage {
    flex-direction: column;
    overflow-y: scroll;
  }
}
