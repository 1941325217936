.ProfileCard {
  z-index: 2;
  width: fit-content;
  height: fit-content;
  padding: 2px!important;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    142deg,
                  var(--secondary-color) 0%,
    rgba(48, 45, 21, 1) 100%
  );
  border-radius: 12px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: calc(336px / 2);
  margin: auto;
  & > div {
    width: 338px;
    min-height: 198px;
    height: fit-content;
    background-color: #060b05;
    //background-image: url('../../../assets/images/card-bg.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left;
    border-radius: 12px;
    padding: 1.25rem;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
  }
}

.PreviewButton {
  cursor: pointer;
  width: 141px;
  height: 27px;
  background-color: #323232;
  border-radius: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 20px;
  top: 20px;
  p {
    font-size: 12px;
    font-weight: 500;
    color: #c9c9c9;
    margin-left: 7px;
    margin-right: 3px;
  }
}

.ProfileContainer {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.ProfileInfoContainer {
  color: var(--primary-color);
  * {
    overflow-wrap: anywhere;
  }
  h2 {
    margin: 0 0 8px;
    font-weight: 600;
    font-size: 20px;
    width: 100%;
  }
  h5 {
    margin: 0 0 4px;
    padding: 0;
    font-weight: 600;
    font-size: 13px;
  }
  h6 {
    margin: 0 0 8px;
    padding: 0;
    font-weight: 400;
    font-size: 11px;
  }
}

.ArrowContainer {
  background-color: #3d3d3d;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 0.5rem;
  color: white;
}

.Selected {
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3rem;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 1.25rem;
  margin-top: 1.25rem;
}

.unSelect {
  background-color: gray;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3rem;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 1.25rem;
  margin-top: 1.25rem;
}

@media (max-width: 1350px) {
  .ProfileCard {
    left: 64px;
  }
}

@media (max-width: 1160px) {
  .ProfileCard {
    & > div {
      width: 296px;
      height: 161px;
    }
  }
}

@media (max-width: 1024px) {
  .ProfileCard {
    left: 60px;

    & > div {
      width: 240px;
      height: 80px;
    }
  }
}

@media (max-width: 768px) {
  .ProfileCard {
    position: absolute;
    left: 0;
    right: 0;
    top: (68px + 35px);
    bottom: unset;
    width: 335px;
    height: 200px;
    & > div {
      width: 331px;
      height: 196px;
    }
  }
}

@media (max-width: 375px) {
  .ProfileCard {
    width: 300px;
    height: 165px;
    & > div {
      width: 296px;
      height: 161px;
    }
  }
}
