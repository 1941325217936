.Header {
  width: 100%;
  height: 72px;
  background-color: #2d2d2d;
  align-items: center;
  justify-content: space-between;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  color: white;
  display: none;
}

.Title {
  font-size: 16px;
  font-weight: 500;
}

.SideButtonContainer {
  background-color: #3f3f3f;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  position: relative;
}

@media (max-width: 768px) {
  .Header {
    width: 100%;
    height: 68px;
    background-color: #2d2d2d;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    color: white;
    display: flex;
  }
}

@media (max-width: 375px) {
  .Title {
    font-size: 14px;
  }
}
